import { useState, useEffect } from "react";
import React from "react";


// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import TextField from '@mui/material/TextField';

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'
import Projects from "../dashboard/components/Projects"
import {useSearchParams} from 'react-router-dom';


// Data

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [OPEN, SET_OPEN] = useState(false);
  const [LOADER, SET_LOADER] = useState(false);
  const [SELECTED_CATEGORY, SET_SELECTED_CATEGORY] = useState(false);
  const [CATEGORY_ID, SET_CATEGORY_ID] = useState(false);
  const [MEVZUAT_NO, SET_MEVZUAT_NO] = useState(0);
  const [KANUN_ISMI, SET_KANUN_ISMI] = useState('');
  const [DETAY, SET_DETAY] = useState('');
  const [LAW_DETAIL, SET_LAW_DETAIL] = useState('');
  const [KANUN, SET_KANUN] = useState([]);
  const [ATTACHMENTS, SET_ATTACHMENTS] = useState([]);
  const [PUBLISH_DATE, SET_PUBLISH_DATE] = useState(new Date());
  const [KURUM_OPEN, SET_KURUM_OPEN] = useState(false);
  const [UST_KURUM_NAME, SET_UST_KURUM_NAME] = useState('');
  const [PARENT_ID, SET_PARENT_ID] = useState('');
  
  const openMenu = ({ currentTarget }) => SET_OPEN(currentTarget);
  const closeMenu = () => SET_OPEN(null);

  const openKurumMenu = ({ currentTarget }) => SET_KURUM_OPEN(currentTarget);
  const closeKurumMenu = () => SET_KURUM_OPEN(null);


  const renderKurumMenu = (
    <Menu
      id="simple-menu"
      anchorEl={KURUM_OPEN}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(KURUM_OPEN)}
      onClose={closeKurumMenu}
    >
      {props.KURUMLAR.map(item=>
      <MenuItem onClick={()=>{SET_UST_KURUM_NAME(item.NAME);SET_PARENT_ID(item.ID);SET_KURUM_OPEN(false)}}>{item.NAME}</MenuItem>
      )}
      <MenuItem onClick={()=>{history('/Kurumlar/ekle');SET_KURUM_OPEN(false)}}>+ Kurum Ekle</MenuItem>

    </Menu>
  );

  useEffect(() => {
    if(props.KURUMLAR[0]?.ID){

    }else {
      props.getKurumList();
    }
  }, [props.KURUMLAR]);

  useEffect(() => {
    if(props.CATEGORY_LIST&&props.CATEGORY_LIST.length>0){
    }else {
      props.getCategory();
    }
  }, [props.CATEGORY_LIST]);

  useEffect(() => {
    if(props.ARCHIVE_DETAIL?.ID){
      console.log("redirect",redirect)
      let data = props.ARCHIVE_DETAIL
      if(data?.ID>0){
        console.log("seçilen kanun",data)
        SET_KANUN(data)
        let result = props.CATEGORY_LIST.filter(item=>item.ID==data.CATEGORY_ID)
        
        SET_SELECTED_CATEGORY(result[0].CATEGORY_NAME);
        SET_CATEGORY_ID(result[0].ID);
        SET_MEVZUAT_NO(data.MEVZUAT_NO);
        SET_KANUN_ISMI(data.KANUN_ISMI);
        SET_DETAY(data.DETAY);
        SET_LAW_DETAIL(data.LAW_DETAIL);
        SET_ATTACHMENTS(data.ATTACHMENTS)
        SET_PUBLISH_DATE(data.PUBLISH_DATE)
        SET_UST_KURUM_NAME(data.PARENT_NAME)
        SET_PARENT_ID(data.KURUM_ID)
      }else {
        alert('Böyle bir haber bulunmamaktadır.')
      }
    }else {
      props.getArchiveDetail(searchParams.get('id'));
    }
  }, [props.ARCHIVE_DETAIL]);
  

  useEffect(() => {
    if (props.UPDATE_ARCHIVE_STATUS==1) {
      SET_LOADER(true)
    }else if(props.UPDATE_ARCHIVE_STATUS==2&&props.UPLOAD_PDF_OR_IMG_COUNTER==0){
      props.getData();
      SET_LOADER(false)
      alert('Veri başarıyla güncellendi')
    }else if(props.UPDATE_ARCHIVE_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.UPDATE_ARCHIVE_ERROR))
    }
  }, [props.UPDATE_ARCHIVE_STATUS])

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={OPEN}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(OPEN)}
      onClose={closeMenu}
    >
      {props.CATEGORY_LIST.map(item=>
      <MenuItem onClick={()=>{SET_SELECTED_CATEGORY(item.CATEGORY_NAME);SET_CATEGORY_ID(item.ID);SET_OPEN(false)}}>{item.CATEGORY_NAME}</MenuItem>
      )}
      <MenuItem onClick={()=>{history('/kategori/ekle');SET_OPEN(false)}}>+ Kategori Ekle</MenuItem>

    </Menu>
  );

  function addArchive(){
    if(SELECTED_CATEGORY){
      props.addArchive({CATEGORY_ID,MEVZUAT_NO,KANUN_ISMI,DETAY,LAW_DETAIL})
    }else {
      alert('Lütfen kategori seçiniz')
    }
  }

  
  useEffect(() => {
    if (props.UPLOAD_PDF_OR_IMG_COUNTER>0) {
      console.log('counter',props.UPLOAD_PDF_OR_IMG_COUNTER)
      SET_LOADER(true)
    }else if(props.UPLOAD_PDF_OR_IMG_COUNTER==0&&props.UPLOAD_PDF_OR_IMG_STATUS==2&&LOADER){
      SET_LOADER(false)
      alert('Veri başarıyla güncellendi.')
    }
    if(props.UPLOAD_PDF_OR_IMG_STATUS==3){
      alert('Yüklenirken hata oluştu'+props.UPLOAD_PDF_OR_IMG_ERROR)
    }
  }, [props.UPLOAD_PDF_OR_IMG_COUNTER])

  useEffect(() => {
    if (props.ADD_ARCHIVE_STATUS==1) {
      SET_LOADER(true)
    }else if(props.ADD_ARCHIVE_STATUS==2&&props.UPLOAD_PDF_OR_IMG_COUNTER==0){
      console.log('counterrr',props.UPLOAD_PDF_OR_IMG_COUNTER)
      props.getData();
      SET_LOADER(false)
      alert('Veri başarıyla güncellendi.')
      history("/Kanunlar")
    }else if(props.ADD_ARCHIVE_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.ADD_ARCHIVE_ERROR))
    }
  }, [props.ADD_ARCHIVE_STATUS])

  function handleClick(e,i) {
    if(e?.DATE_INSERT){
      props.deleteArchiveAttachment(props.ARCHIVE_DETAIL.ID,e.ID)
    }else {
      let array = ATTACHMENTS.filter(item => item.lastModified != e.lastModified);
      SET_ATTACHMENTS(array)
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {KANUN?.ID>0?
      <SuiBox p={9}>
        <SuiBox component="form" role="form">
          <SuiBox mb={9} ml={0.5}>
            <Grid container spacing={1}>
              <Grid item md={12} xl={6}>
                {SELECTED_CATEGORY?
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography variant="h5">
                    Seçilen Kategori: {SELECTED_CATEGORY}
                  </SuiTypography>
                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={openMenu}> 
                    Kategori Seç
                  </SuiButton>
                </SuiBox>
                :
                <SuiButton
                  variant="gradient"
                  color="info"
                  onClick={openMenu}> 
                  Kategori Seç
                </SuiButton>
                }
                {renderMenu}
              </Grid>
              <Grid item md={12} xl={3}>
                <SuiBox >
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Yayınlanma Tarihi
                  </SuiTypography>
                <SuiInput
                  type="date"
                  placeholder="Yayınlanma Tarihi"
                  value={PUBLISH_DATE}
                  onChange={(e) => SET_PUBLISH_DATE(e.target.value)}
                />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={3}>
                <SuiBox mb={2}>
                  {UST_KURUM_NAME?
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography variant="h5">
                      Seçilen Kurum: {UST_KURUM_NAME}
                    </SuiTypography>
                    <SuiButton
                      variant="gradient"
                      color="info"
                      onClick={openKurumMenu}> 
                      Kurum Seç
                    </SuiButton>
                  </SuiBox>
                  :
                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={openKurumMenu}> 
                    Kurum Seç
                  </SuiButton>
                  }
                  {renderKurumMenu}
                  </SuiBox>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item md={12} xl={6}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Kanun Başlığı
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Kategori Başlığı"
                    value={KANUN_ISMI}
                    onChange={(e) => SET_KANUN_ISMI(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={6} xl={3}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Mevzuat No
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Mevzuat No"
                    value={MEVZUAT_NO}
                    onChange={(e) => SET_MEVZUAT_NO(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={6} xl={3}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Detay
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Kanunlar Tertip: 5 Resmî Gazete Tarihi: 05.07.2022 Sayısı: 31887 Kabul Tarihi: 01.07.2022"
                    value={DETAY}
                    onChange={(e) => SET_DETAY(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={12}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Kanun İçeriği
                    </SuiTypography>
                  </SuiBox>
                  <textarea
                    value={LAW_DETAIL}
                    onChange={(e) => SET_LAW_DETAIL(e.target.value)}
                    style={{width:'100%',height:'600px'}}
        />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={12}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Ekler
                    </SuiTypography>
                  </SuiBox>
                    <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf" disabled={LOADER} className="mb-2" name="myImage"  onChange={(event) => SET_ATTACHMENTS([...ATTACHMENTS, event.target.files[0]])}/>
                    <SuiBox mb={1} mt={3} ml={3}>
                    {ATTACHMENTS.map((item,index)=>
                    item?.DATE_INSERT?
                    item.TYPE==2?
                        <SuiBox>
                          <a target="_blank" href={apiUrl+'file/showAttachment/'+item.NAME} style={{marginLeft:30}}>PDF İsmi: {item.NAME.slice(0,40)}</a>
                          <a href="#" onClick={()=>handleClick(item,index)} id={index} style={{marginLeft:25, color:'red', fontSize:16}}>Sil</a>
                        </SuiBox>
                      :
                        <SuiBox>
                          <a target="_blank" href={apiUrl+'image/showAttachment/'+item.NAME} style={{marginLeft:30}}>Resim İsmi: {item.NAME.slice(0,40)}</a>
                          <a href="#" onClick={()=>handleClick(item,index)} id={index} style={{marginLeft:25, color:'red', fontSize:16}}>Sil</a>
                        </SuiBox>
                    :
                    item.type=='application/pdf'?
                    <SuiBox>
                      <a target="_blank" href={URL.createObjectURL(new Blob([item], {"type":"application/pdf"}))} style={{marginLeft:30}}>PDF İsmi: {item.name.slice(0,40)}</a>
                      <a href="#" onClick={()=>handleClick(item,index)} id={index} style={{marginLeft:25, color:'red', fontSize:16}}>Sil</a>
                    </SuiBox>
                    :
                      <SuiBox>
                        <a target="_blank" href={URL.createObjectURL(item)} style={{marginLeft:30}}>Resim İsmi: {item.name.slice(0,40)}</a>
                        <a href="#" onClick={()=>handleClick(item,index)} id={index} style={{marginLeft:25, color:'red', fontSize:16}}>Sil</a>
                      </SuiBox>
                    )}
                      </SuiBox>
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={LOADER}
              onClick={()=>{if(SELECTED_CATEGORY){
                props.updateArchive(KANUN.ID,ATTACHMENTS.filter(item=>item?.type).length,ATTACHMENTS.filter(item=>item?.type),{CATEGORY_ID, MEVZUAT_NO, KANUN_ISMI, DETAY, LAW_DETAIL, KURUM_ID:PARENT_ID})
              }else {
                alert('Lütfen kategori seçiniz')
              }}}
            >
              {LOADER?
              <Oval height = "15" width = "15"/>
              :
              'Düzenle'
              }
            </SuiButton>
          </SuiBox>

        </SuiBox>
      </SuiBox>
      :
      <Oval height = "15" width = "15"/>
      }
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const {  ARCHIVE_LIST, ARCHIVE_LIST_LOADER, ARCHIVE_LIST_STATUS, CATEGORY_LIST, CATEGORY_LIST_LOADER, CATEGORY_LIST_STATUS, ARCHIVE_DETAIL, ARCHIVE_DETAIL_LOADER, ARCHIVE_DETAIL_STATUS, ARCHIVE_DETAIL_FAILUE, UPDATE_ARCHIVE_LOADER, UPDATE_ARCHIVE_STATUS, KURUMLAR, UPLOAD_PDF_OR_IMG_COUNTER, UPLOAD_PDF_OR_IMG_STATUS, UPLOAD_PDF_OR_IMG_ERROR} = state.app;
  return {  ARCHIVE_LIST, ARCHIVE_LIST_LOADER, ARCHIVE_LIST_STATUS, CATEGORY_LIST, CATEGORY_LIST_LOADER, CATEGORY_LIST_STATUS, ARCHIVE_DETAIL, ARCHIVE_DETAIL_LOADER, ARCHIVE_DETAIL_STATUS, ARCHIVE_DETAIL_FAILUE, UPDATE_ARCHIVE_LOADER, UPDATE_ARCHIVE_STATUS, KURUMLAR, UPLOAD_PDF_OR_IMG_COUNTER, UPLOAD_PDF_OR_IMG_STATUS, UPLOAD_PDF_OR_IMG_ERROR};
};

const mapDispatchToProps = (dispatch, props) => ({
  getCategory: () => {
    dispatch({
      type: $.GET_CATEGORY_LIST
    });
  },
  getData: () => {
    dispatch({
      type: $.GET_ARCHIVE_LIST
    });
  },
  getArchiveDetail: (ID) => {
    dispatch({
      type: $.GET_ARCHIVE_DETAIL,
      ID
    });
  },
  deleteArchiveAttachment: (ARCHIVE_ID,ID) => {
    dispatch({
      type: $.DELETE_ARCHIVE_ATTACHMENT,
      ARCHIVE_ID,
      ID
    });
  },
  updateArchive: (ID,EK_DURUM,EKLER,payload) => {
    dispatch({
      type: $.UPDATE_ARCHIVE,
      EK_DURUM,
      EKLER,
      ID,
      payload
    });
  },
  getKurumList: (payload) => {
    dispatch({
      type: $.GET_KURUMLAR_LIST,
      payload
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
