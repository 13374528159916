import { useState, useEffect } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";


// Data
import data from "./data/data";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {useSearchParams} from 'react-router-dom';

function Dashboard(props) {
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [LOADER, SET_LOADER] = useState(false);
  
  
  useEffect(() => {
    if(props.QUESTION_LIST&&props.QUESTION_LIST.length>0){
    }else {
      props.data();
    }
  }, [props.QUESTION_LIST]);

  if(searchParams.has('delete_comment_id')){
    if(location.search.split("=")[2]>0){
      if (confirm('Bu yorumu silmek istiyor musunuz?') == true) {
        props.deleteQuestion(searchParams.get('delete_comment_id'))
        history("/Sorular?id=" +searchParams.get('id'))
      } else {
        history("/Sorular?id=" +searchParams.get('id'))
      }
    }
  }

    
  useEffect(() => {
    if (props.DELETE_QUESTION_STATUS==1) {
      SET_LOADER(true)
    }else if(props.DELETE_QUESTION_STATUS==2){
      props.data();
      SET_LOADER(false)
      alert('Yorum başarıyla silindi')
    }else if(props.DELETE_QUESTION_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.DELETE_QUESTION_ERROR))
    }
  }, [props.DELETE_QUESTION_STATUS])


  const { columns1, rows2 } = data(props.QUESTION_LIST);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
      <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns1} rows={rows2} />
            </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { QUESTION_LIST, QUESTION_LIST_LOADER, QUESTION_LIST_STATUS, DELETE_QUESTION_LOADER, DELETE_QUESTION_STATUS } = state.app;
  return { QUESTION_LIST, QUESTION_LIST_LOADER, QUESTION_LIST_STATUS, DELETE_QUESTION_LOADER, DELETE_QUESTION_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  data: () => {
    dispatch({
      type: $.GET_QUESTION_LIST
    });
  },
  deleteQuestion: (ID) => {
    dispatch({
      type: $.DELETE_QUESTION,
      ID
    });
  },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
