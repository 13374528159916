import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import React from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import { apiUrl, authHeader } from "../../../config";
// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import SuiButton from "components/SuiButton";

// Data

import { connect } from "react-redux";
import * as $ from "../../../redux/action";
import { useNavigate, NavLink } from "react-router-dom";

function userlistData(props) {
  const arr = [];

  function Author({ name, email, img }) {
    return (
      <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <Avatar uri={img} />
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="button" fontWeight="medium">
            {name}
          </SuiTypography>
          <SuiTypography variant="caption" color="secondary">
            {email}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    );
  }

  function Function({ job, org }) {
    return (
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </SuiTypography>
        <SuiTypography variant="caption" color="secondary">
          {org}
        </SuiTypography>
      </SuiBox>
    );
  }

  function Avatar({ uri}) {
    return (
      <SuiBox mr={2}>
        <SuiAvatar src={apiUrl + "image/show/" + uri} alt="Logo" />
      </SuiBox>
    );
  }

  console.log(props);
  props.map((data) => {
    arr.push({
      "Etkinlik İsmi":  (
        <SuiTypography variant="caption" color="secondary" fontWeight="medium">
          {data.ACTIVITY_HEADER}
        </SuiTypography>
      ),
      "Etkinlik Detayı":  (
        <SuiTypography variant="caption" color="secondary" fontWeight="medium">
          {data.ACTIVITY_CONTENT.slice(0,100)}...
        </SuiTypography>
      ),
      "Etkinlik Resmi": (
        <Avatar uri={data.ACTIVITY_IMAGE} />
      ),
      "Etkinlik Tarihi": (
        <SuiTypography variant="caption" color="secondary" fontWeight="medium">
          {data.ACTIVITY_DATE}
        </SuiTypography>
      ),
      düzenleme: (
        <NavLink
          to={{ pathname: "/etkinlik/detay?id=" + data.ID, aboutProps: data }}
          className="nav-link"
          rel="noopener noreferrer"
        >
          <SuiButton variant="gradient" color="warning">
            Düzenleme
          </SuiButton>
        </NavLink>
      ),
      sil: (
        <NavLink
        to={{ pathname: '/etkinlikler?delete_comment_id='+data.ID, aboutProps: { data: props } }}
          className="nav-link"
          rel="noopener noreferrer"
        >
          <SuiButton color="info">
            Sil
          </SuiButton>
        </NavLink>
      ),
    });
  });
  return {
    columns1: [
      { name: "Etkinlik İsmi", align: "left" },
      { name: "Etkinlik Detayı", align: "left" },
      { name: "Etkinlik Resmi", align: "left" },
      { name: "Etkinlik Tarihi", align: "left" },
      { name: "düzenleme", align: "center" },
      { name: "sil", align: "center" },
    ],

    rows2: arr,
  };
}

export default userlistData;
