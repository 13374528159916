import { useState, useEffect } from "react";
import React from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'
import Projects from "../dashboard/components/Projects"
import {useSearchParams} from 'react-router-dom';
import moment from 'moment'

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Dashboard(props) {
  let history = useNavigate();
  const [IMAGE_SHOW, SET_IMAGE_SHOW] = useState(false);
  const [IMAGE_UPLOAD, SET_IMAGE_UPLOAD] = useState('');
  const [LOADER, SET_LOADER] = useState(false);
  const [ACTIVITY_HEADER, SET_ACTIVITY_HEADER] = useState('');
  const [ACTIVITY, SET_ACTIVITY] = useState('');
  const [ACTIVITY_CONTENT, SET_ACTIVITY_CONTENT] = useState('');
  const [ACTIVITY_URI, SET_ACTIVITY_URI] = useState('');
  const [ACTIVITY_DATE, SET_ACTIVITY_DATE] = useState(new Date());
  const [searchParams, setSearchParams] = useSearchParams();
  const [ACTIVITY_DATE_FINISH, SET_ACTIVITY_DATE_FINISH] = useState(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      SET_IMAGE_SHOW(true)
      SET_IMAGE_UPLOAD(img)
    }
  };

    
  useEffect(() => {
    if (props.UPDATE_ACTIVITIES_STATUS==1) {
      SET_LOADER(true)
    }else if(props.UPDATE_ACTIVITIES_STATUS==2){
      alert('Etkinlik güncellendi')
      SET_LOADER(false)
      props.getIssueList();
    }else if(props.UPDATE_ACTIVITIES_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.UPDATE_ACTIVITIES_ERROR))
    }
  }, [props.UPDATE_ACTIVITIES_STATUS])
  
  useEffect(() => {
    if(props.ACTIVITIES_LIST[0]?.ID){
      let data = props.ACTIVITIES_LIST.filter(item=>item.ID==searchParams.get('id'))[0];
      if(data?.ID>0){
        console.log("seçilen etkinlik",data)
        SET_ACTIVITY(data)
        SET_IMAGE_UPLOAD(data.ACTIVITY_IMAGE)
        SET_ACTIVITY_HEADER(data.ACTIVITY_HEADER)
        SET_ACTIVITY_CONTENT(data.ACTIVITY_CONTENT)
        SET_ACTIVITY_URI(data.ACTIVITY_URI)
        SET_ACTIVITY_DATE(data.ACTIVITY_DATE.split('T')[0])
        SET_ACTIVITY_DATE_FINISH(data.ACTIVITY_DATE_FINISH?data.ACTIVITY_DATE_FINISH.split('T')[0]:null)
        // SET_CATEGORY(data) 
        // SET_CATEGORY_NAME(data.CATEGORY_NAME)        
      }else {
        alert('Böyle bir Etkinlik bulunmamaktadır.')
      }
    }else {
      props.getIssueList();
    }
  }, [props.ACTIVITIES_LIST]);
  

  
  useEffect(() => {
    if (props.ADD_ACTIVITIES_STATUS==1) {
      SET_LOADER(true)
    }else if(props.ADD_ACTIVITIES_STATUS==2){
      props.getIssueList();
      SET_LOADER(false)
      alert('Etkinlik başarıyla eklendi')
      history("/etkinlikler")
    }else if(props.ADD_ACTIVITIES_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.ADD_ACTIVITIES_ERROR))
    }
  }, [props.ADD_ACTIVITIES_STATUS])
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox p={9}>
        <SuiBox component="form" role="form">
          <SuiBox mb={9} ml={0.5}>
            <Grid container spacing={1}>
              <Grid md={12} xl={12}>
                
              </Grid>
              <Grid md={9} xl={9}>
                  <SuiBox
                    component="img"
                    src={IMAGE_SHOW?URL.createObjectURL(IMAGE_UPLOAD):apiUrl + "image/show/"+IMAGE_UPLOAD}
                    height="200px"
                  />
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Etkinlik Fotoğrafı<br></br>
                    </SuiTypography>
                    <input type="file" disabled={LOADER} className="mb-2" name="myImage"  onChange={(event) => onImageChange(event)}/>
                  </SuiBox>
                </Grid>
                <Grid md={3} xl={2} >
                </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox >
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Etkinlik Tarihi
                    </SuiTypography>
                  <SuiInput
                    type="date"
                    placeholder="Etkinlik Başlığı"
                    value={ACTIVITY_DATE}
                    onChange={(e) => SET_ACTIVITY_DATE(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox >
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Etkinlik Bitiş Tarihi
                    </SuiTypography>
                  <SuiInput
                    type="date"
                    placeholder="Etkinlik Bitiş Tarihi"
                    value={ACTIVITY_DATE_FINISH}
                    onChange={(e) => SET_ACTIVITY_DATE_FINISH(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox >
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Etkinlik Başlığı
                    </SuiTypography>
                  <SuiInput
                    type="text"
                    placeholder="Etkinlik Başlığı"
                    value={ACTIVITY_HEADER}
                    onChange={(e) => SET_ACTIVITY_HEADER(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={6}>
                  <SuiBox >
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Etkinlik Linki
                      </SuiTypography>
                    <SuiInput
                      type="text"
                      placeholder="Etkinlik Linki"
                      value={ACTIVITY_URI}
                      onChange={(e) => SET_ACTIVITY_URI(e.target.value)}
                    />
                  </SuiBox>
              </Grid>
              <Grid item xs={12} sm={12} xl={12}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Etkinlik İçeriği
                    </SuiTypography>
                  </SuiBox>
                   <SuiInput
                    type="text"
                    placeholder="Etkinlik içeriği"
                    value={ACTIVITY_CONTENT}
                    onChange={(e) => SET_ACTIVITY_CONTENT(e.target.value)}
                  />
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid md={12} xl={6}>
              </Grid>
              <Grid md={12} xl={6}>
            </Grid>
          </Grid>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={LOADER}
              onClick={()=>{SET_IMAGE_SHOW(false);props.updateActivity(IMAGE_SHOW,ACTIVITY.ID,{ACTIVITY_IMAGE:IMAGE_UPLOAD,ACTIVITY_HEADER,ACTIVITY_CONTENT,ACTIVITY_URI,ACTIVITY_DATE,ACTIVITY_DATE_FINISH})}}
            >
              {LOADER?
              <Oval height = "15" width = "15"/>
              :
              'Güncelle'
              }
            </SuiButton>
          </SuiBox>

        </SuiBox>

      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { ACTIVITIES_LIST, ACTIVITIES_LIST_LOADER, ACTIVITIES_LIST_STATUS, UPDATE_ACTIVITIES_LOADER, UPDATE_ACTIVITIES_STATUS } = state.app;
  return { ACTIVITIES_LIST, ACTIVITIES_LIST_LOADER, ACTIVITIES_LIST_STATUS, UPDATE_ACTIVITIES_LOADER, UPDATE_ACTIVITIES_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  updateActivity: (IMAGE,ID,payload) => {
    dispatch({
      type: $.UPDATE_ACTIVITIES,
      ID,
      IMAGE,
      payload
    });
  },
  getIssueList: () => {
    dispatch({
      type: $.GET_ACTIVITIES_LIST
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
