import { useState, useEffect } from "react";
import React from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'
import Projects from "../dashboard/components/Projects"
import {useSearchParams} from 'react-router-dom';


// Data
import userlistData from "./data/data";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [UST_KURUM_NAME, SET_UST_KURUM_NAME] = useState('');
  const [PARENT_ID, SET_PARENT_ID] = useState(0);
  const [NAME, SET_NAME] = useState('');
  const [LOADER, SET_LOADER] = useState(false);
  const [OPEN, SET_OPEN] = useState(false);

  const openMenu = ({ currentTarget }) => SET_OPEN(currentTarget);
  const closeMenu = () => SET_OPEN(null);



  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={OPEN}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(OPEN)}
      onClose={closeMenu}
    >
      {props.KURUMLAR.map(item=>
      <MenuItem onClick={()=>{SET_UST_KURUM_NAME(item.NAME);SET_PARENT_ID(item.ID);SET_OPEN(false)}}>{item.NAME}</MenuItem>
      )}
      <MenuItem onClick={()=>{history('/Kurumlar/ekle');SET_OPEN(false)}}>+ Kurum Ekle</MenuItem>

    </Menu>
  );
  
  useEffect(() => {
    if(props.KURUMLAR&&props.KURUMLAR.length>0){
      console.log('gelen kurumlar',props.KURUMLAR)
    }else {
      props.getKurumlar();
    }
  }, [props.KURUMLAR]);

  useEffect(() => {
    if (props.ADD_KURUM_STATUS==1) {
      SET_LOADER(true)
    }else if(props.ADD_KURUM_STATUS==2){
      props.getCategoryList();
      SET_LOADER(false)
      props.getKurumlar();
      alert('Kurum başarıyla eklendi')
      history("/Kurumlar")
    }else if(props.ADD_KURUM_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.ADD_CATEGORY_ERROR))
    }
  }, [props.ADD_KURUM_STATUS])
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox p={9}>
        <SuiBox component="form" role="form">
          <SuiBox mb={9} ml={0.5}>
            <Grid container spacing={1}>
              <Grid item md={12} xl={6}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Kurum İsmi
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Kurum İsmi"
                    value={NAME}
                    onChange={(e) => SET_NAME(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={6}>
                {UST_KURUM_NAME?
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography variant="h5">
                    Seçilen Üst Kurum: {UST_KURUM_NAME}
                  </SuiTypography>
                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={openMenu}> 
                    Üst Kurum Seç
                  </SuiButton>
                </SuiBox>
                :
                <SuiButton
                  variant="gradient"
                  color="info"
                  onClick={openMenu}> 
                  Üst Kurum Seç
                </SuiButton>
                }
                {renderMenu}
              </Grid>
            </Grid>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={LOADER}
              onClick={()=>props.addKurum(NAME,PARENT_ID)}
            >
              {LOADER?
              <Oval height = "15" width = "15"/>
              :
              'Ekle'
              }
            </SuiButton>
          </SuiBox>

        </SuiBox>

      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { KURUMLAR, ADD_KURUM_LOADER, ADD_KURUM_STATUS } = state.app;
  return { KURUMLAR, ADD_KURUM_LOADER, ADD_KURUM_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  getCategoryList: () => {
    dispatch({
      type: $.GET_CATEGORY_LIST
    });
  },
  addKurum: (NAME,PARENT_ID) => {
    dispatch({
      type: $.ADD_KURUM,
      PAYLOAD: { NAME, PARENT_ID}
    });
  },
  getKurumlar: () => {
    dispatch({
      type: $.GET_KURUMLAR_LIST
    });
  },  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
