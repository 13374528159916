import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import React from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import { apiUrl, authHeader } from "../../../config";
// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import SuiButton from "components/SuiButton";

// Data

import { connect } from "react-redux";
import * as $ from "../../../redux/action";
import { useNavigate, NavLink } from "react-router-dom";

function userlistData(props) {
  const arr = [];

  function Author({ name, email, img }) {
    return (
      <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <Avatar uri={img} />
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="button" fontWeight="medium">
            {name}
          </SuiTypography>
          <SuiTypography variant="caption" color="secondary">
            {email}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    );
  }


  function Function({ CAT_ID }) {
    let result = props.CATEGORY.filter(item=>item.ID==CAT_ID)
    return (
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="caption" fontWeight="medium" color="text">
          {result[0].CATEGORY_NAME}
        </SuiTypography>
      </SuiBox>
    );
  }

  function Avatar({ uri}) {
    return (
      <SuiBox mr={2}>
        <SuiAvatar src={apiUrl + "image/show/" + uri} alt="Logo" />
      </SuiBox>
    );
  }

  console.log(props);
  props.ARCHIVE.map((data) => {
    arr.push({
      "Kategori": (<Function CAT_ID={data.CATEGORY_ID} />
      ),
      "Mevzuat No": (
        <SuiTypography variant="caption" color="secondary" fontWeight="medium">
          {data.MEVZUAT_NO}
        </SuiTypography>
      ),
      "Kanun İsmi": (
        <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {data.KANUN_ISMI.substring(0, 100)}...
          </SuiTypography>
        </SuiBox>
      ),
      "Detay": (
        <SuiTypography variant="caption" color="secondary" fontWeight="medium">
          {data.DETAY.substring(0, 25)}...
        </SuiTypography>
      ),
      "Kanun Detay": (
        <SuiTypography variant="caption" color="secondary" fontWeight="medium">
          {data.LAW_DETAIL.substring(0, 25)}...
        </SuiTypography>
      ),
      düzenleme: (
        <NavLink
        to={{ pathname: '/kanun/detay?id='+data.ID, aboutProps: { data: props } }}
          className="nav-link"
          rel="noopener noreferrer"
        >
          <SuiButton variant="gradient" color="warning">
            Düzenleme
          </SuiButton>
        </NavLink>
      ),
      sil: (
        <NavLink
        to={{ pathname: '/Kanunlar?delete_comment_id='+data.ID, aboutProps: { data: props } }}
          className="nav-link"
          rel="noopener noreferrer"
        >
          <SuiButton color="info">
            Sil
          </SuiButton>
        </NavLink>
      ),
    });
  });
  return {
    columns1: [
      { name: "Kategori", align: "left" },
      { name: "Mevzuat No", align: "left" },
      { name: "Kanun İsmi", align: "left" },
      { name: "Detay", align: "left" },
      { name: "Kanun Detay", align: "left" },
      { name: "düzenleme", align: "center" },
      { name: "sil", align: "center" },
    ],

    rows2: arr,
  };
}

export default userlistData;
