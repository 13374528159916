export const apiUrl = "https://apiNew.yoktr.com:4001/";

export function authHeader() {
  const user = JSON.parse(localStorage.getItem("token"));
  if (user) {
    return { headers: { Authorization: `Bearer ${user}` } };
  } else {
    return {};
  }
}

export function onlyAuthorization() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    return { Authorization: `Bearer ${user.accessToken}` };
  } else {
    return {};
  }
}

export default (apiUrl, authHeader, onlyAuthorization);
