import { useState, useEffect } from "react";
import React from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'
import Projects from "../dashboard/components/Projects"
import {useSearchParams} from 'react-router-dom';


// Data
import userlistData from "./data/data";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [CATEGORY, SET_CATEGORY] = useState([]);
  const [CATEGORY_NAME, SET_CATEGORY_NAME] = useState('');
  const [LOADER, SET_LOADER] = useState(false);
  const [IMAGE_SHOW, SET_IMAGE_SHOW] = useState(false);
  const [IMAGE_UPLOAD, SET_IMAGE_UPLOAD] = useState('');
  const [DELETE_CATEGORY_CONFIRM, SET_DELETE_CATEGORY_CONFIRM] = useState(false);
  const [OPEN, SET_OPEN] = useState(false);
  const [SELECTED_CATEGORY, SET_SELECTED_CATEGORY] = useState(false);
  const [PARENT_ID, SET_PARENT_ID] = useState(0);
  
  const openMenu = ({ currentTarget }) => SET_OPEN(currentTarget);
  const closeMenu = () => SET_OPEN(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={OPEN}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(OPEN)}
      onClose={closeMenu}
    >
      {props.CATEGORY_LIST.map(item=>
      <MenuItem onClick={()=>{SET_SELECTED_CATEGORY(item.CATEGORY_NAME);SET_PARENT_ID(item.ID);SET_OPEN(false)}}>{item.CATEGORY_NAME}</MenuItem>
      )}
      <MenuItem onClick={()=>{history('/kategori/ekle');SET_OPEN(false)}}>+ Kategori Ekle</MenuItem>

    </Menu>
  );
  
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      SET_IMAGE_SHOW(true)
      SET_IMAGE_UPLOAD(img)
    }
  };

  
  useEffect(() => {
    if (props.ADD_CATEGORY_STATUS==1) {
      SET_LOADER(true)
    }else if(props.ADD_CATEGORY_STATUS==2){
      props.getCategoryList();
      SET_LOADER(false)
      alert('Kategori başarıyla eklendi')
      history("/Kategoriler")
    }else if(props.ADD_CATEGORY_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.ADD_CATEGORY_ERROR))
    }
  }, [props.ADD_CATEGORY_STATUS])
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox p={9}>
        <SuiBox component="form" role="form">
          <SuiBox mb={9} ml={0.5}>
            <Grid container spacing={1}>
              <Grid md={12} xl={12}>
                
              </Grid>
              <Grid md={9} xl={9}>
                  <SuiBox
                    component="img"
                    src={IMAGE_SHOW?URL.createObjectURL(IMAGE_UPLOAD):apiUrl + "image/show/default.png"}
                    height="200px"
                  />
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Kategori Fotoğrafı
                    </SuiTypography>
                    <input type="file" disabled={LOADER} className="mb-2" name="myImage"  onChange={(event) => onImageChange(event)}/>
                  </SuiBox>
                </Grid>
                <Grid md={3} xl={2} >
                </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Kategori Başlığı
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Kategori Başlığı"
                    value={CATEGORY_NAME}
                    onChange={(e) => SET_CATEGORY_NAME(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={6}>
              {SELECTED_CATEGORY?
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography variant="h5">
                    Seçilen Üst Kategori: {SELECTED_CATEGORY}
                  </SuiTypography>
                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={openMenu}> 
                    Üst Kategori Seç
                  </SuiButton>
                </SuiBox>
                :
                <SuiButton
                  variant="gradient"
                  color="info"
                  onClick={openMenu}> 
                  Üst Kategori Seç
                </SuiButton>
                }
                {renderMenu}
              </Grid>
            </Grid>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={LOADER}
              onClick={()=>props.addCategory(CATEGORY.ID,IMAGE_SHOW?IMAGE_UPLOAD:false,{CATEGORY_IMAGE:IMAGE_SHOW?IMAGE_UPLOAD:CATEGORY.CATEGORY_IMAGE,CATEGORY_NAME,PARENT_ID})}
            >
              {LOADER?
              <Oval height = "15" width = "15"/>
              :
              'Ekle'
              }
            </SuiButton>
          </SuiBox>

        </SuiBox>

      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { ADD_CATEGORY_LOADER, ADD_CATEGORY_STATUS, CATEGORY_LIST } = state.app;
  return { ADD_CATEGORY_LOADER, ADD_CATEGORY_STATUS, CATEGORY_LIST };
};

const mapDispatchToProps = (dispatch, props) => ({
  getCategoryList: () => {
    dispatch({
      type: $.GET_CATEGORY_LIST
    });
  },
  addCategory: (ID,IMAGE,payload) => {
    dispatch({
      type: $.ADD_CATEGORY,
      ID,
      IMAGE,
      payload
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
