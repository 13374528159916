import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { connect } from "react-redux";
import * as $ from "../../../redux/action";
import { useNavigate } from "react-router-dom";

function SignIn(props) {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let history = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    console.log("loader", props.LOGIN_LOADER);
    console.log("props", props.LOGIN_STATUS);
    if (props.LOGIN_STATUS == 2) {
      history("/dashboard");
    }
  }, [props]);

  return (
    <CoverLayout
      title="Hoşgeldiniz"
      description="Yoktr paneline giriş yapmak için email ve şifrenizi giriniz."
      image={curved9}
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <SuiInput type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Şifre
            </SuiTypography>
          </SuiBox>
          <SuiInput type="password" placeholder="Şire " value={password} onChange={(e) => setPassword(e.target.value)}  />
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" color="info" fullWidth onClick={() => props.getLogin(email,password)}>
            {props.LOGIN_LOADER ? <CircularProgress color="inherit" size={15} /> : "Giriş Yap"}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { USER, LOGIN_LOADER, LOGIN_STATUS } = state.app;
  return { USER, LOGIN_LOADER, LOGIN_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  getLogin: (EMAIL,PASSWORD) => {
    dispatch({
      type: $.GET_LOGIN,
      payload:{
       EMAIL,
       PASSWORD
      }
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
