
import Dashboard from "layouts/dashboard";
import User from "layouts/user";
import Etkinlikler from "layouts/etkinlikler";
import Haberler from "layouts/haberler";
import Kanunlar from "layouts/kanunlar";
import Kategoriler from "layouts/kategoriler";
import Sorular from "layouts/sorular";
import SignIn from "layouts/authentication/sign-in";
import UserDetay from "layouts/user/detay";
import HaberDetay from "layouts/haberler/detay";
import HaberEkle from "layouts/haberler/ekle";
import KategoriDetay from "layouts/kategoriler/detay";
import KategoriEkle from "layouts/kategoriler/ekle";
import SoruCevapList from "layouts/sorular/cevapList";
import SoruDetay from "layouts/sorular/detay";
import KanunEkle from "layouts/kanunlar/ekle";
import KanunDetay from "layouts/kanunlar/detay";
import EtkinlikEkle from "layouts/etkinlikler/ekle";
import EtkinlikDetay from "layouts/etkinlikler/detay";
import KurumlarList from "layouts/kurumlar";
import KurumlarEkle from "layouts/kurumlar/ekle";
import KurumlarDetay from "layouts/kurumlar/detay";

import Shop from "examples/Icons/Shop";
import Document from "examples/Icons/Document";

const routes = [
  {
    type: "collapse",
    name: "Anasayfa",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Kullanıcılar",
    key: "kullanici",
    route: "/kullanici",
    icon: <Shop size="12px" />,
    component: <User />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Haberler",
    key: "haberler",
    route: "/haberler",
    icon: <Shop size="12px" />,
    component: <Haberler />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Kategoriler",
    key: "Kategoriler",
    route: "/Kategoriler",
    icon: <Shop size="12px" />,
    component: <Kategoriler />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sorular",
    key: "Sorular",
    route: "/Sorular",
    icon: <Shop size="12px" />,
    component: <Sorular />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Kanuni Veriler",
    key: "Kanunlar",
    route: "/Kanunlar",
    icon: <Shop size="12px" />,
    component: <Kanunlar />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Etkinlikler",
    key: "Etkinlikler",
    route: "/Etkinlikler",
    icon: <Shop size="12px" />,
    component: <Etkinlikler />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Kurumlar",
    key: "Kurumlar",
    route: "/Kurumlar",
    icon: <Shop size="12px" />,
    component: <KurumlarList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Kurumlar",
    key: "Kurumlar",
    route: "/Kurumlar/ekle",
    icon: <Shop size="12px" />,
    component: <KurumlarEkle />,
    noCollapse: true,
    hide: true,
  },
  {
    type: "collapse",
    name: "Kurumlar",
    key: "Kurumlar",
    route: "/Kurumlar/detay",
    icon: <Shop size="12px" />,
    component: <KurumlarDetay />,
    noCollapse: true,
    hide: true,
  },
  {
    type: "collapse",
    name: "Giriş yap",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    hide: true,
  },

  {
    type: "collapse",
    name: "User Detay",
    key: "userDetay",
    route: "/user/detay",
    icon: <Document size="12px" />,
    component: <UserDetay />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Haber Detay",
    key: "haberDetay",
    route: "/haber/detay",
    icon: <Document size="12px" />,
    component: <HaberDetay />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Haber Ekle",
    key: "haberEkle",
    route: "/haber/ekle",
    icon: <Document size="12px" />,
    component: <HaberEkle />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Kategori Detay",
    key: "kategoriDetay",
    route: "/kategori/detay",
    icon: <Document size="12px" />,
    component: <KategoriDetay />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Kategori Ekle",
    key: "kategoriEkle",
    route: "/kategori/ekle",
    icon: <Document size="12px" />,
    component: <KategoriEkle />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Soru CevapList",
    key: "soruCevapList",
    route: "/soru/cevapList",
    icon: <Document size="12px" />,
    component: <SoruCevapList />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Soru Detay",
    key: "soruDetay",
    route: "/soru/detay",
    icon: <Document size="12px" />,
    component: <SoruDetay />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Kanun Ekle",
    key: "kanunEkle",
    route: "/kanun/ekle",
    icon: <Document size="12px" />,
    component: <KanunEkle />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Kanun Detay",
    key: "kanunDetay",
    route: "/kanun/detay",
    icon: <Document size="12px" />,
    component: <KanunDetay />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Etkinlik ekle",
    key: "Etkinlikekle",
    route: "/etkinlik/ekle",
    icon: <Document size="12px" />,
    component: <EtkinlikEkle />,
    hide: true,
  },
  {
    type: "collapse",
    name: "Etkinlik detay",
    key: "Etkinlikdetay",
    route: "/etkinlik/detay",
    icon: <Document size="12px" />,
    component: <EtkinlikDetay />,
    hide: true,
  },

];

export default routes;
