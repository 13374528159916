import * as $ from "../action/index";

const STATE = {
  USER: [],
  LOGIN_LOADER: false,
  LOGIN_STATUS: false,

  SUMMARY: [],
  SUMMARY_LOADER: false,
  SUMMARY_STATUS: 0,
  
  USER_LIST: [],
  USER_LIST_LOADER: false,
  USER_LIST_STATUS: 0,
  
  QUESTION_LIST: [],
  QUESTION_LIST_LOADER: false,
  QUESTION_LIST_STATUS: 0,
    
  ISSUE_LIST: [],
  ISSUE_LIST_LOADER: false,
  ISSUE_LIST_STATUS: 0,
      
  ARCHIVE_LIST: [],
  ARCHIVE_LIST_LOADER: false,
  ARCHIVE_LIST_STATUS: 0,
        
  ACTIVITIES_LIST: [],
  ACTIVITIES_LIST_LOADER: false,
  ACTIVITIES_LIST_STATUS: 0, 
          
  CATEGORY_LIST: [],
  CATEGORY_LIST_LOADER: false,
  CATEGORY_LIST_STATUS: 0,  
            
  CONFIG: [],
  CONFIG_LOADER: false,
  CONFIG_STATUS: 0,  
              
  CONFIG_EDIT_LOADER: false,
  CONFIG_EDIT_STATUS: 0, 
                
  DELETE_USER_LOADER: false,
  DELETE_USER_STATUS: 0,  
                  
  RESET_PASSWORD_USER_LOADER: false,
  RESET_PASSWORD_USER_STATUS: 0,  
                    
  UPDATE_USER_LOADER: false,
  UPDATE_USER_STATUS: 0,  
  
  DELETE_ISSUE_LOADER: false,
  DELETE_ISSUE_STATUS: 0,  
    
  UPDATE_ISSUE_LOADER: false,
  UPDATE_ISSUE_STATUS: 0,

  DELETE_ISSUE_COMMENT_LOADER: false,
  DELETE_ISSUE_COMMENT_STATUS: 0,  
  
  ADD_ISSUE_LOADER: false,
  ADD_ISSUE_STATUS: 0,  
    
  DELETE_CATEGORY_LOADER: false,
  DELETE_CATEGORY_STATUS: 0,  
      
  UPDATE_CATEGORY_LOADER: false,
  UPDATE_CATEGORY_STATUS: 0,  
        
  ADD_CATEGORY_LOADER: false,
  ADD_CATEGORY_STATUS: 0,  
          
  DELETE_QUESTION_ANSWER_LOADER: false,
  DELETE_QUESTION_ANSWER_STATUS: 0,  
            
  DELETE_QUESTION_LOADER: false,
  DELETE_QUESTION_STATUS: 0,  
              
  UPDATE_QUESTION_LOADER: false,
  UPDATE_QUESTION_STATUS: 0,  
                
  ADD_ARCHIVE_LOADER: false,
  ADD_ARCHIVE_STATUS: 0,  
                  
  DELETE_ARCHIVE_LOADER: false,
  DELETE_ARCHIVE_STATUS: 0,  
                    
  ARCHIVE_DETAIL_LOADER: false,
  ARCHIVE_DETAIL_STATUS: 0,  
  ARCHIVE_DETAIL_FAILUE: 0,  
  ARCHIVE_DETAIL: [], 

  UPDATE_ARCHIVE_LOADER: false,
  UPDATE_ARCHIVE_STATUS: 0,  
  
  ADD_ACTIVITIES_LOADER: false,
  ADD_ACTIVITIES_STATUS: 0, 
   
  DELETE_ACTIVITIES_LOADER: false,
  DELETE_ACTIVITIES_STATUS: 0,    

  UPDATE_ACTIVITIES_LOADER: false,
  UPDATE_ACTIVITIES_STATUS: 0,  

  SEND_NOTIFICATION_USER_LOADER: false,
  SEND_NOTIFICATION_USER_STATUS: 0,  

  KURUMLAR_LOADER: false,
  KURUMLAR_STATUS: 0,  
  KURUMLAR_FAILUE: 0,  
  KURUMLAR: [], 

  ADD_KURUM_LOADER: false,
  ADD_KURUM_STATUS: 0,  
  
  DELETE_KURUM_LOADER: false,
  DELETE_KURUM_STATUS: 0,   

  UPDATE_KURUM_LOADER: false,
  UPDATE_KURUM_STATUS: 0,  

  UPDATE_KURUM_LOADER: false,
  UPDATE_KURUM_STATUS: 0,  

  UPLOAD_PDF_OR_IMG_COUNTER: 0,
  UPLOAD_PDF_OR_IMG_STATUS: 0,  
  UPLOAD_PDF_OR_IMG_ERROR: false,
  
  UPLOAD_QUESTION_ATTACH_COUNTER: 0,
  UPLOAD_QUESTION_ATTACH_STATUS: 0,  
  UPLOAD_QUESTION_ATTACH_ERROR: false,
};

const reducer = function (state = STATE, action) {
  switch (action.type) {

    case $.GET_LOGIN_REQUEST:
      return { ...state, LOGIN_LOADER: true, LOGIN_STATUS: 1 };

    case $.GET_LOGIN_REQUEST_SUCCESS:
      return { ...state, LOGIN_LOADER: true, LOGIN_STATUS: 2, USER: action.PAYLOAD };

    case $.GET_LOGIN_REQUEST_FAILURE:
      return { ...state, LOGIN_LOADER: true, LOGIN_STATUS: 3 };

    case $.GET_LOGIN_REQUEST_END:
      return { ...state, LOGIN_LOADER: false, LOGIN_STATUS: 0 };

    
    case $.GET_SUMMARY_REQUEST:
      return { ...state, SUMMARY_LOADER: true, SUMMARY_STATUS: 1 };

    case $.GET_SUMMARY_REQUEST_SUCCESS: {
      return { ...state, SUMMARY_LOADER: true, SUMMARY_STATUS: 2, SUMMARY: action.PAYLOAD };
    }
    case $.GET_SUMMARY_REQUEST_FAILURE:
      return { ...state, SUMMARY_LOADER: true, SUMMARY_STATUS: 3 };

    case $.GET_SUMMARY_REQUEST_END:
      return { ...state, SUMMARY_LOADER: false, SUMMARY_STATUS: 0 };
  

    case $.GET_USER_LIST_REQUEST:
      return { ...state, USER_LIST_LOADER: true, USER_LIST_STATUS: 1 };

    case $.GET_USER_LIST_REQUEST_SUCCESS: {
      return { ...state, USER_LIST_LOADER: true, USER_LIST_STATUS: 2, USER_LIST: action.PAYLOAD };
    }
    case $.GET_USER_LIST_REQUEST_FAILURE:
      return { ...state, USER_LIST_LOADER: true, USER_LIST_STATUS: 3 };

    case $.GET_USER_LIST_REQUEST_END:
      return { ...state, USER_LIST_LOADER: false, USER_LIST_STATUS: 0 };
    

    case $.GET_QUESTION_LIST_REQUEST:
      return { ...state, QUESTION_LIST_LOADER: true, QUESTION_LIST_STATUS: 1 };

    case $.GET_QUESTION_LIST_REQUEST_SUCCESS: {
      return { ...state, QUESTION_LIST_LOADER: true, QUESTION_LIST_STATUS: 2, QUESTION_LIST: action.PAYLOAD };
    }
    case $.GET_QUESTION_LIST_REQUEST_FAILURE:
      return { ...state, QUESTION_LIST_LOADER: true, QUESTION_LIST_STATUS: 3 };

    case $.GET_QUESTION_LIST_REQUEST_END:
      return { ...state, QUESTION_LIST_LOADER: false, QUESTION_LIST_STATUS: 0 };
      

    case $.GET_ISSUE_LIST_REQUEST:
      return { ...state, ISSUE_LIST_LOADER: true, ISSUE_LIST_STATUS: 1 };

    case $.GET_ISSUE_LIST_REQUEST_SUCCESS: {
      return { ...state, ISSUE_LIST_LOADER: true, ISSUE_LIST_STATUS: 2, ISSUE_LIST: action.PAYLOAD };
    }
    case $.GET_ISSUE_LIST_REQUEST_FAILURE:
      return { ...state, ISSUE_LIST_LOADER: true, ISSUE_LIST_STATUS: 3 };

    case $.GET_ISSUE_LIST_REQUEST_END:
      return { ...state, ISSUE_LIST_LOADER: false, ISSUE_LIST_STATUS: 0 };
        

    case $.GET_ARCHIVE_LIST_REQUEST:
      return { ...state, ARCHIVE_LIST_LOADER: true, ARCHIVE_LIST_STATUS: 1 };

    case $.GET_ARCHIVE_LIST_REQUEST_SUCCESS: {
      return { ...state, ARCHIVE_LIST_LOADER: true, ARCHIVE_LIST_STATUS: 2, ARCHIVE_LIST: action.PAYLOAD };
    }
    case $.GET_ARCHIVE_LIST_REQUEST_FAILURE:
      return { ...state, ARCHIVE_LIST_LOADER: true, ARCHIVE_LIST_STATUS: 3 };

    case $.GET_ARCHIVE_LIST_REQUEST_END:
      return { ...state, ARCHIVE_LIST_LOADER: false, ARCHIVE_LIST_STATUS: 0 };
        
      
    case $.GET_ACTIVITIES_LIST_REQUEST:
      return { ...state, ACTIVITIES_LIST_LOADER: true, ACTIVITIES_LIST_STATUS: 1 };

    case $.GET_ACTIVITIES_LIST_REQUEST_SUCCESS: {
      return { ...state, ACTIVITIES_LIST_LOADER: true, ACTIVITIES_LIST_STATUS: 2, ACTIVITIES_LIST: action.PAYLOAD };
    }
    case $.GET_ACTIVITIES_LIST_REQUEST_FAILURE:
      return { ...state, ACTIVITIES_LIST_LOADER: true, ACTIVITIES_LIST_STATUS: 3 };

    case $.GET_ACTIVITIES_LIST_REQUEST_END:
      return { ...state, ACTIVITIES_LIST_LOADER: false, ACTIVITIES_LIST_STATUS: 0 };
         

    case $.GET_CATEGORY_LIST_REQUEST:
      return { ...state, CATEGORY_LIST_LOADER: true, CATEGORY_LIST_STATUS: 1 };

    case $.GET_CATEGORY_LIST_REQUEST_SUCCESS: {
      return { ...state, CATEGORY_LIST_LOADER: true, CATEGORY_LIST_STATUS: 2, CATEGORY_LIST: action.PAYLOAD };
    }
    case $.GET_CATEGORY_LIST_REQUEST_FAILURE:
      return { ...state, CATEGORY_LIST_LOADER: true, CATEGORY_LIST_STATUS: 3 };

    case $.GET_CATEGORY_LIST_REQUEST_END:
      return { ...state, CATEGORY_LIST_LOADER: false, CATEGORY_LIST_STATUS: 0 };
          

    case $.GET_CONFIG_REQUEST:
      return { ...state, CONFIG_LOADER: true, CONFIG_STATUS: 1 };

    case $.GET_CONFIG_REQUEST_SUCCESS: {
      return { ...state, CONFIG_LOADER: true, CONFIG_STATUS: 2, CONFIG: action.PAYLOAD };
    }
    case $.GET_CONFIG_REQUEST_FAILURE:
      return { ...state, CONFIG_LOADER: true, CONFIG_STATUS: 3 };

    case $.GET_CONFIG_REQUEST_END:
      return { ...state, CONFIG_LOADER: false, CONFIG_STATUS: 0 };
                  
    
    case $.EDIT_CONFIG_REQUEST:
      return { ...state, CONFIG_EDIT_LOADER: true, CONFIG_EDIT_STATUS: 1 };

    case $.EDIT_CONFIG_REQUEST_SUCCESS: {
      return { ...state, CONFIG_EDIT_LOADER: true, CONFIG_EDIT_STATUS: 2 };
    }
    case $.EDIT_CONFIG_REQUEST_FAILURE:
      return { ...state, CONFIG_EDIT_LOADER: true, CONFIG_EDIT_STATUS: 3 };

    case $.EDIT_CONFIG_REQUEST_END:
      return { ...state, CONFIG_EDIT_LOADER: false, CONFIG_EDIT_STATUS: 0 };
              
    
    case $.DELETE_USER_REQUEST:
      return { ...state, DELETE_USER_LOADER: true, DELETE_USER_STATUS: 1 };

    case $.DELETE_USER_REQUEST_SUCCESS: {
      return { ...state, DELETE_USER_LOADER: true, DELETE_USER_STATUS: 2 };
    }
    case $.DELETE_USER_REQUEST_FAILURE:
      return { ...state, DELETE_USER_LOADER: true, DELETE_USER_STATUS: 3 };

    case $.DELETE_USER_REQUEST_END:
      return { ...state, DELETE_USER_LOADER: false, DELETE_USER_STATUS: 0 };
       

    case $.RESET_PASSWORD_USER_REQUEST:
      return { ...state, RESET_PASSWORD_USER_LOADER: true, RESET_PASSWORD_USER_STATUS: 1 };

    case $.RESET_PASSWORD_USER_REQUEST_SUCCESS: {
      return { ...state, RESET_PASSWORD_USER_LOADER: true, RESET_PASSWORD_USER_STATUS: 2 };
    }
    case $.RESET_PASSWORD_USER_REQUEST_FAILURE:
      return { ...state, RESET_PASSWORD_USER_LOADER: true, RESET_PASSWORD_USER_STATUS: 3 };

    case $.RESET_PASSWORD_USER_REQUEST_END:
      return { ...state, RESET_PASSWORD_USER_LOADER: false, RESET_PASSWORD_USER_STATUS: 0 };

      
    case $.UPDATE_USER_REQUEST:
      return { ...state, UPDATE_USER_LOADER: true, UPDATE_USER_STATUS: 1 };

    case $.UPDATE_USER_REQUEST_SUCCESS: {
      return { ...state, UPDATE_USER_LOADER: true, UPDATE_USER_STATUS: 2 };
    }
    case $.UPDATE_USER_REQUEST_FAILURE:
      return { ...state, UPDATE_USER_LOADER: true, UPDATE_USER_STATUS: 3 };

    case $.UPDATE_USER_REQUEST_END:
      return { ...state, UPDATE_USER_LOADER: false, UPDATE_USER_STATUS: 0 };

      
    case $.DELETE_ISSUE_REQUEST:
      return { ...state, DELETE_ISSUE_LOADER: true, DELETE_ISSUE_STATUS: 1 };

    case $.DELETE_ISSUE_REQUEST_SUCCESS: {
      return { ...state, DELETE_ISSUE_LOADER: true, DELETE_ISSUE_STATUS: 2 };
    }
    case $.DELETE_ISSUE_REQUEST_FAILURE:
      return { ...state, DELETE_ISSUE_LOADER: true, DELETE_ISSUE_STATUS: 3 };

    case $.DELETE_ISSUE_REQUEST_END:
      return { ...state, DELETE_ISSUE_LOADER: false, DELETE_ISSUE_STATUS: 0 };
              

    case $.UPDATE_ISSUE_REQUEST:
      return { ...state, UPDATE_ISSUE_LOADER: true, UPDATE_ISSUE_STATUS: 1 };

    case $.UPDATE_ISSUE_REQUEST_SUCCESS: {
      return { ...state, UPDATE_ISSUE_LOADER: true, UPDATE_ISSUE_STATUS: 2 };
    }
    case $.UPDATE_ISSUE_REQUEST_FAILURE:
      return { ...state, UPDATE_ISSUE_LOADER: true, UPDATE_ISSUE_STATUS: 3 };

    case $.UPDATE_ISSUE_REQUEST_END:
      return { ...state, UPDATE_ISSUE_LOADER: false, UPDATE_ISSUE_STATUS: 0 };

          
    case $.DELETE_ISSUE_COMMENT_REQUEST:
      return { ...state, DELETE_ISSUE_COMMENT_LOADER: true, DELETE_ISSUE_COMMENT_STATUS: 1 };

    case $.DELETE_ISSUE_COMMENT_REQUEST_SUCCESS: {
      return { ...state, DELETE_ISSUE_COMMENT_LOADER: true, DELETE_ISSUE_COMMENT_STATUS: 2 };
    }
    case $.DELETE_ISSUE_COMMENT_REQUEST_FAILURE:
      return { ...state, DELETE_ISSUE_COMMENT_LOADER: true, DELETE_ISSUE_COMMENT_STATUS: 3 };

    case $.DELETE_ISSUE_COMMENT_REQUEST_END:
      return { ...state, DELETE_ISSUE_COMMENT_LOADER: false, DELETE_ISSUE_COMMENT_STATUS: 0 };
        
                      
    case $.ADD_ISSUE_REQUEST:
      return { ...state, ADD_ISSUE_LOADER: true, ADD_ISSUE_STATUS: 1 };

    case $.ADD_ISSUE_REQUEST_SUCCESS: {
      return { ...state, ADD_ISSUE_LOADER: true, ADD_ISSUE_STATUS: 2 };
    }
    case $.ADD_ISSUE_REQUEST_FAILURE:
      return { ...state, ADD_ISSUE_LOADER: true, ADD_ISSUE_STATUS: 3 };

    case $.ADD_ISSUE_REQUEST_END:
      return { ...state, ADD_ISSUE_LOADER: false, ADD_ISSUE_STATUS: 0 };

                      
    case $.DELETE_CATEGORY_REQUEST:
      return { ...state, DELETE_CATEGORY_LOADER: true, DELETE_CATEGORY_STATUS: 1 };

    case $.DELETE_CATEGORY_REQUEST_SUCCESS: {
      return { ...state, DELETE_CATEGORY_LOADER: true, DELETE_CATEGORY_STATUS: 2 };
    }
    case $.DELETE_CATEGORY_REQUEST_FAILURE:
      return { ...state, DELETE_CATEGORY_LOADER: true, DELETE_CATEGORY_STATUS: 3 };

    case $.DELETE_CATEGORY_REQUEST_END:
      return { ...state, DELETE_CATEGORY_LOADER: false, DELETE_CATEGORY_STATUS: 0 };
          
        
                      
    case $.UPDATE_CATEGORY_REQUEST:
      return { ...state, UPDATE_CATEGORY_LOADER: true, UPDATE_CATEGORY_STATUS: 1 };

    case $.UPDATE_CATEGORY_REQUEST_SUCCESS: {
      return { ...state, UPDATE_CATEGORY_LOADER: true, UPDATE_CATEGORY_STATUS: 2 };
    }
    case $.UPDATE_CATEGORY_REQUEST_FAILURE:
      return { ...state, UPDATE_CATEGORY_LOADER: true, UPDATE_CATEGORY_STATUS: 3 };

    case $.UPDATE_CATEGORY_REQUEST_END:
      return { ...state, UPDATE_CATEGORY_LOADER: false, UPDATE_CATEGORY_STATUS: 0 };
      

    case $.ADD_CATEGORY_REQUEST:
      return { ...state, ADD_CATEGORY_LOADER: true, ADD_CATEGORY_STATUS: 1 };

    case $.ADD_CATEGORY_REQUEST_SUCCESS: {
      return { ...state, ADD_CATEGORY_LOADER: true, ADD_CATEGORY_STATUS: 2 };
    }
    case $.ADD_CATEGORY_REQUEST_FAILURE:
      return { ...state, ADD_CATEGORY_LOADER: true, ADD_CATEGORY_STATUS: 3 };

    case $.ADD_CATEGORY_REQUEST_END:
      return { ...state, ADD_CATEGORY_LOADER: false, ADD_CATEGORY_STATUS: 0 };      
       
              

    case $.DELETE_QUESTION_ANSWER_REQUEST:
      return { ...state, DELETE_QUESTION_ANSWER_LOADER: true, DELETE_QUESTION_ANSWER_STATUS: 1 };

    case $.DELETE_QUESTION_ANSWER_REQUEST_SUCCESS: {
      return { ...state, DELETE_QUESTION_ANSWER_LOADER: true, DELETE_QUESTION_ANSWER_STATUS: 2 };
    }
    case $.DELETE_QUESTION_ANSWER_REQUEST_FAILURE:
      return { ...state, DELETE_QUESTION_ANSWER_LOADER: true, DELETE_QUESTION_ANSWER_STATUS: 3 };

    case $.DELETE_QUESTION_ANSWER_REQUEST_END:
      return { ...state, DELETE_QUESTION_ANSWER_LOADER: false, DELETE_QUESTION_ANSWER_STATUS: 0 };      
       

    case $.DELETE_QUESTION_REQUEST:
      return { ...state, DELETE_QUESTION_LOADER: true, DELETE_QUESTION_STATUS: 1 };

    case $.DELETE_QUESTION_REQUEST_SUCCESS: {
      return { ...state, DELETE_QUESTION_LOADER: true, DELETE_QUESTION_STATUS: 2 };
    }
    case $.DELETE_QUESTION_REQUEST_FAILURE:
      return { ...state, DELETE_QUESTION_LOADER: true, DELETE_QUESTION_STATUS: 3 };

    case $.DELETE_QUESTION_REQUEST_END:
      return { ...state, DELETE_QUESTION_LOADER: false, DELETE_QUESTION_STATUS: 0 };      


    case $.UPDATE_QUESTION_REQUEST:
      return { ...state, UPDATE_QUESTION_LOADER: true, UPDATE_QUESTION_STATUS: 1 };

    case $.UPDATE_QUESTION_REQUEST_SUCCESS: {
      return { ...state, UPDATE_QUESTION_LOADER: true, UPDATE_QUESTION_STATUS: 2 };
    }
    case $.UPDATE_QUESTION_REQUEST_FAILURE:
      return { ...state, UPDATE_QUESTION_LOADER: true, UPDATE_QUESTION_STATUS: 3 };

    case $.UPDATE_QUESTION_REQUEST_END:
      return { ...state, UPDATE_QUESTION_LOADER: false, UPDATE_QUESTION_STATUS: 0 };      
    

    case $.ADD_ARCHIVE_REQUEST:
      return { ...state, ADD_ARCHIVE_LOADER: true, ADD_ARCHIVE_STATUS: 1 };

    case $.ADD_ARCHIVE_REQUEST_SUCCESS: {
      return { ...state, ADD_ARCHIVE_LOADER: true, ADD_ARCHIVE_STATUS: 2 };
    }
    case $.ADD_ARCHIVE_REQUEST_FAILURE:
      return { ...state, ADD_ARCHIVE_LOADER: true, ADD_ARCHIVE_STATUS: 3 };

    case $.ADD_ARCHIVE_REQUEST_END:
      return { ...state, ADD_ARCHIVE_LOADER: false, ADD_ARCHIVE_STATUS: 0 };      
          

    case $.DELETE_ARCHIVE_REQUEST:
      return { ...state, DELETE_ARCHIVE_LOADER: true, DELETE_ARCHIVE_STATUS: 1 };

    case $.DELETE_ARCHIVE_REQUEST_SUCCESS: {
      return { ...state, DELETE_ARCHIVE_LOADER: true, DELETE_ARCHIVE_STATUS: 2 };
    }
    case $.DELETE_ARCHIVE_REQUEST_FAILURE:
      return { ...state, DELETE_ARCHIVE_LOADER: true, DELETE_ARCHIVE_STATUS: 3 };

    case $.DELETE_ARCHIVE_REQUEST_END:
      return { ...state, DELETE_ARCHIVE_LOADER: false, DELETE_ARCHIVE_STATUS: 0 };      
        
                  

    case $.GET_ARCHIVE_DETAIL_REQUEST:
      return { ...state, ARCHIVE_DETAIL_LOADER: true, ARCHIVE_DETAIL_STATUS: 1 };

    case $.GET_ARCHIVE_DETAIL_REQUEST_SUCCESS: {
      return { ...state, ARCHIVE_DETAIL_LOADER: true, ARCHIVE_DETAIL_STATUS: 2 , ARCHIVE_DETAIL: action.PAYLOAD };
    }
    case $.GET_ARCHIVE_DETAIL_REQUEST_FAILURE:
      return { ...state, ARCHIVE_DETAIL_LOADER: true, ARCHIVE_DETAIL_STATUS: 3, ARCHIVE_DETAIL_FAILUE:action.ERROR };

    case $.GET_ARCHIVE_DETAIL_REQUEST_END:
      return { ...state, ARCHIVE_DETAIL_LOADER: false, ARCHIVE_DETAIL_STATUS: 0 };      
          
                          

    case $.UPDATE_ARCHIVE_REQUEST:
      return { ...state, UPDATE_ARCHIVE_LOADER: true, UPDATE_ARCHIVE_STATUS: 1 };

    case $.UPDATE_ARCHIVE_REQUEST_SUCCESS: {
      return { ...state, UPDATE_ARCHIVE_LOADER: true, UPDATE_ARCHIVE_STATUS: 2 };
    }
    case $.UPDATE_ARCHIVE_REQUEST_FAILURE:
      return { ...state, UPDATE_ARCHIVE_LOADER: true, UPDATE_ARCHIVE_STATUS: 3 };

    case $.UPDATE_ARCHIVE_REQUEST_END:
      return { ...state, UPDATE_ARCHIVE_LOADER: false, UPDATE_ARCHIVE_STATUS: 0 };      
          
                  
                          

    case $.ADD_ACTIVITIES_REQUEST:
      return { ...state, ADD_ACTIVITIES_LOADER: true, ADD_ACTIVITIES_STATUS: 1 };

    case $.ADD_ACTIVITIES_REQUEST_SUCCESS: {
      return { ...state, ADD_ACTIVITIES_LOADER: true, ADD_ACTIVITIES_STATUS: 2 };
    }
    case $.ADD_ACTIVITIES_REQUEST_FAILURE:
      return { ...state, ADD_ACTIVITIES_LOADER: true, ADD_ACTIVITIES_STATUS: 3 };

    case $.ADD_ACTIVITIES_REQUEST_END:
      return { ...state, ADD_ACTIVITIES_LOADER: false, ADD_ACTIVITIES_STATUS: 0 };      
      

    case $.DELETE_ACTIVITIES_REQUEST:
      return { ...state, DELETE_ACTIVITIES_LOADER: true, DELETE_ACTIVITIES_STATUS: 1 };

    case $.DELETE_ACTIVITIES_REQUEST_SUCCESS: {
      return { ...state, DELETE_ACTIVITIES_LOADER: true, DELETE_ACTIVITIES_STATUS: 2 };
    }
    case $.DELETE_ACTIVITIES_REQUEST_FAILURE:
      return { ...state, DELETE_ACTIVITIES_LOADER: true, DELETE_ACTIVITIES_STATUS: 3 };

    case $.DELETE_ACTIVITIES_REQUEST_END:
      return { ...state, DELETE_ACTIVITIES_LOADER: false, DELETE_ACTIVITIES_STATUS: 0 };      
        
        
        
    case $.UPDATE_ACTIVITIES_REQUEST:
      return { ...state, UPDATE_ACTIVITIES_LOADER: true, UPDATE_ACTIVITIES_STATUS: 1 };

    case $.UPDATE_ACTIVITIES_REQUEST_SUCCESS: {
      return { ...state, UPDATE_ACTIVITIES_LOADER: true, UPDATE_ACTIVITIES_STATUS: 2 };
    }
    case $.UPDATE_ACTIVITIES_REQUEST_FAILURE:
      return { ...state, UPDATE_ACTIVITIES_LOADER: true, UPDATE_ACTIVITIES_STATUS: 3 };

    case $.UPDATE_ACTIVITIES_REQUEST_END:
      return { ...state, UPDATE_ACTIVITIES_LOADER: false, UPDATE_ACTIVITIES_STATUS: 0 };      
        
        
    case $.SEND_NOTIFICATION_USER_REQUEST:
      return { ...state, SEND_NOTIFICATION_USER_LOADER: true, SEND_NOTIFICATION_USER_STATUS: 1 };

    case $.SEND_NOTIFICATION_USER_REQUEST_SUCCESS: {
      return { ...state, SEND_NOTIFICATION_USER_LOADER: true, SEND_NOTIFICATION_USER_STATUS: 2 };
    }
    case $.SEND_NOTIFICATION_USER_REQUEST_FAILURE:
      return { ...state, SEND_NOTIFICATION_USER_LOADER: true, SEND_NOTIFICATION_USER_STATUS: 3 };

    case $.SEND_NOTIFICATION_USER_REQUEST_END:
      return { ...state, SEND_NOTIFICATION_USER_LOADER: false, SEND_NOTIFICATION_USER_STATUS: 0 };      
        

    case $.GET_KURUMLAR_LIST_REQUEST:
      return { ...state, KURUMLAR_LOADER: true, KURUMLAR_STATUS: 1 };

    case $.GET_KURUMLAR_LIST_REQUEST_SUCCESS: {
      return { ...state, KURUMLAR_LOADER: true, KURUMLAR_STATUS: 2, KURUMLAR: action.PAYLOAD };
    }
    case $.GET_KURUMLAR_LIST_REQUEST_FAILURE:
      return { ...state, KURUMLAR_LOADER: true, KURUMLAR_STATUS: 3 };

    case $.GET_KURUMLAR_LIST_REQUEST_END:
      return { ...state, KURUMLAR_LOADER: false, KURUMLAR_STATUS: 0 };      
          
      
    case $.ADD_KURUM_REQUEST:
      return { ...state, ADD_KURUM_LOADER: true, ADD_KURUM_STATUS: 1 };

    case $.ADD_KURUM_REQUEST_SUCCESS: {
      return { ...state, ADD_KURUM_LOADER: true, ADD_KURUM_STATUS: 2 };
    }
    case $.ADD_KURUM_REQUEST_FAILURE:
      return { ...state, ADD_KURUM_LOADER: true, ADD_KURUM_STATUS: 3 };

    case $.ADD_KURUM_REQUEST_END:
      return { ...state, ADD_KURUM_LOADER: false, ADD_KURUM_STATUS: 0 };      
          
            
    case $.DELETE_KURUM_REQUEST:
      return { ...state, DELETE_KURUM_LOADER: true, DELETE_KURUM_STATUS: 1 };

    case $.DELETE_KURUM_REQUEST_SUCCESS: {
      return { ...state, DELETE_KURUM_LOADER: true, DELETE_KURUM_STATUS: 2 };
    }
    case $.DELETE_KURUM_REQUEST_FAILURE:
      return { ...state, DELETE_KURUM_LOADER: true, DELETE_KURUM_STATUS: 3 };

    case $.DELETE_KURUM_REQUEST_END:
      return { ...state, DELETE_KURUM_LOADER: false, DELETE_KURUM_STATUS: 0 };      
        
      
    case $.UPDATE_KURUM_REQUEST:
      return { ...state, UPDATE_KURUM_LOADER: true, UPDATE_KURUM_STATUS: 1 };

    case $.UPDATE_KURUM_REQUEST_SUCCESS: {
      return { ...state, UPDATE_KURUM_LOADER: true, UPDATE_KURUM_STATUS: 2 };
    }
    case $.UPDATE_KURUM_REQUEST_FAILURE:
      return { ...state, UPDATE_KURUM_LOADER: true, UPDATE_KURUM_STATUS: 3 };

    case $.UPDATE_KURUM_REQUEST_END:
      return { ...state, UPDATE_KURUM_LOADER: false, UPDATE_KURUM_STATUS: 0 };      
          

    case $.POST_UPLOAD_IMAGE_OR_PDF_REQUEST:
      return { ...state, UPLOAD_PDF_OR_IMG_STATUS:1, UPLOAD_PDF_OR_IMG_COUNTER:state.UPLOAD_PDF_OR_IMG_COUNTER+1 };

    case $.POST_UPLOAD_IMAGE_OR_PDF_REQUEST_SUCCESS:
      return { ...state, UPLOAD_PDF_OR_IMG_STATUS:2, UPLOAD_PDF_OR_IMG_COUNTER:state.UPLOAD_PDF_OR_IMG_COUNTER-1};      
            
    case $.POST_UPLOAD_IMAGE_OR_PDF_REQUEST_FAILURE:
      return { ...state, UPLOAD_PDF_OR_IMG_STATUS:3, UPLOAD_PDF_OR_IMG_COUNTER:state.UPLOAD_PDF_OR_IMG_COUNTER-1, UPLOAD_PDF_OR_IMG_ERROR:action.ERROR};      
          

      
    case $.ADD_QUESTION_ATTACHMENT_REQUEST:
      return { ...state, UPLOAD_QUESTION_ATTACH_STATUS:1, UPLOAD_QUESTION_ATTACH_COUNTER:state.UPLOAD_QUESTION_ATTACH_COUNTER+1 };

    case $.ADD_QUESTION_ATTACHMENT_REQUEST_SUCCESS:
      return { ...state, UPLOAD_QUESTION_ATTACH_STATUS:2, UPLOAD_QUESTION_ATTACH_COUNTER:state.UPLOAD_QUESTION_ATTACH_COUNTER-1};      
            
    case $.ADD_QUESTION_ATTACHMENT_REQUEST_FAILURE:
      return { ...state, UPLOAD_QUESTION_ATTACH_STATUS:3, UPLOAD_QUESTION_ATTACH_COUNTER:state.UPLOAD_QUESTION_ATTACH_COUNTER-1, UPLOAD_QUESTION_ATTACH_ERROR:action.ERROR};      

    default:
      return state;
  }
};

export default reducer;
