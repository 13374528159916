export const GET_LOGIN = "redux/GET_LOGIN";
export const GET_LOGIN_REQUEST = "redux/GET_LOGIN_REQUEST";
export const GET_LOGIN_REQUEST_SUCCESS = "redux/GET_LOGIN_REQUEST_SUCCESS";
export const GET_LOGIN_REQUEST_FAILURE = "redux/GET_LOGIN_REQUEST_FAILURE";
export const GET_LOGIN_REQUEST_END = "redux/GET_LOGIN_REQUEST_END";

export const GET_SUMMARY = "redux/GET_SUMMARY";
export const GET_SUMMARY_REQUEST = "redux/GET_SUMMARY_REQUEST";
export const GET_SUMMARY_REQUEST_SUCCESS = "redux/GET_SUMMARY_REQUEST_SUCCESS";
export const GET_SUMMARY_REQUEST_FAILURE = "redux/GET_SUMMARY_REQUEST_FAILURE";
export const GET_SUMMARY_REQUEST_END = "redux/GET_SUMMARY_REQUEST_END";

export const GET_USER_LIST = "redux/GET_USER_LIST";
export const GET_USER_LIST_REQUEST = "redux/GET_USER_LIST_REQUEST";
export const GET_USER_LIST_REQUEST_SUCCESS = "redux/GET_USER_LIST_REQUEST_SUCCESS";
export const GET_USER_LIST_REQUEST_FAILURE = "redux/GET_USER_LIST_REQUEST_FAILURE";
export const GET_USER_LIST_REQUEST_END = "redux/GET_USER_LIST_REQUEST_END";

export const GET_QUESTION_LIST = "redux/GET_QUESTION_LIST";
export const GET_QUESTION_LIST_REQUEST = "redux/GET_QUESTION_LIST_REQUEST";
export const GET_QUESTION_LIST_REQUEST_SUCCESS = "redux/GET_QUESTION_LIST_REQUEST_SUCCESS";
export const GET_QUESTION_LIST_REQUEST_FAILURE = "redux/GET_QUESTION_LIST_REQUEST_FAILURE";
export const GET_QUESTION_LIST_REQUEST_END = "redux/GET_QUESTION_LIST_REQUEST_END";

export const GET_ISSUE_LIST = "redux/GET_ISSUE_LIST";
export const GET_ISSUE_LIST_REQUEST = "redux/GET_ISSUE_LIST_REQUEST";
export const GET_ISSUE_LIST_REQUEST_SUCCESS = "redux/GET_ISSUE_LIST_REQUEST_SUCCESS";
export const GET_ISSUE_LIST_REQUEST_FAILURE = "redux/GET_ISSUE_LIST_REQUEST_FAILURE";
export const GET_ISSUE_LIST_REQUEST_END = "redux/GET_ISSUE_LIST_REQUEST_END";

export const GET_ARCHIVE_LIST = "redux/GET_ARCHIVE_LIST";
export const GET_ARCHIVE_LIST_REQUEST = "redux/GET_ARCHIVE_LIST_REQUEST";
export const GET_ARCHIVE_LIST_REQUEST_SUCCESS = "redux/GET_ARCHIVE_LIST_REQUEST_SUCCESS";
export const GET_ARCHIVE_LIST_REQUEST_FAILURE = "redux/GET_ARCHIVE_LIST_REQUEST_FAILURE";
export const GET_ARCHIVE_LIST_REQUEST_END = "redux/GET_ARCHIVE_LIST_REQUEST_END";

export const GET_ACTIVITIES_LIST = "redux/GET_ACTIVITIES_LIST";
export const GET_ACTIVITIES_LIST_REQUEST = "redux/GET_ACTIVITIES_LIST_REQUEST";
export const GET_ACTIVITIES_LIST_REQUEST_SUCCESS = "redux/GET_ACTIVITIES_LIST_REQUEST_SUCCESS";
export const GET_ACTIVITIES_LIST_REQUEST_FAILURE = "redux/GET_ACTIVITIES_LIST_REQUEST_FAILURE";
export const GET_ACTIVITIES_LIST_REQUEST_END = "redux/GET_ACTIVITIES_LIST_REQUEST_END";

export const GET_CATEGORY_LIST = "redux/GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_REQUEST = "redux/GET_CATEGORY_LIST_REQUEST";
export const GET_CATEGORY_LIST_REQUEST_SUCCESS = "redux/GET_CATEGORY_LIST_REQUEST_SUCCESS";
export const GET_CATEGORY_LIST_REQUEST_FAILURE = "redux/GET_CATEGORY_LIST_REQUEST_FAILURE";
export const GET_CATEGORY_LIST_REQUEST_END = "redux/GET_CATEGORY_LIST_REQUEST_END";

export const GET_CONFIG = "redux/GET_CONFIG";
export const GET_CONFIG_REQUEST = "redux/GET_CONFIG_REQUEST";
export const GET_CONFIG_REQUEST_SUCCESS = "redux/GET_CONFIG_REQUEST_SUCCESS";
export const GET_CONFIG_REQUEST_FAILURE = "redux/GET_CONFIG_REQUEST_FAILURE";
export const GET_CONFIG_REQUEST_END = "redux/GET_CONFIG_REQUEST_END";

export const EDIT_CONFIG = "redux/EDIT_CONFIG";
export const EDIT_CONFIG_REQUEST = "redux/EDIT_CONFIG_REQUEST";
export const EDIT_CONFIG_REQUEST_SUCCESS = "redux/EDIT_CONFIG_REQUEST_SUCCESS";
export const EDIT_CONFIG_REQUEST_FAILURE = "redux/EDIT_CONFIG_REQUEST_FAILURE";
export const EDIT_CONFIG_REQUEST_END = "redux/EDIT_CONFIG_REQUEST_END";

export const DELETE_USER = "redux/DELETE_USER";
export const DELETE_USER_REQUEST = "redux/DELETE_USER_REQUEST";
export const DELETE_USER_REQUEST_SUCCESS = "redux/DELETE_USER_REQUEST_SUCCESS";
export const DELETE_USER_REQUEST_FAILURE = "redux/DELETE_USER_REQUEST_FAILURE";
export const DELETE_USER_REQUEST_END = "redux/DELETE_USER_REQUEST_END";

export const UPDATE_USER = "redux/UPDATE_USER";
export const UPDATE_USER_REQUEST = "redux/UPDATE_USER_REQUEST";
export const UPDATE_USER_REQUEST_SUCCESS = "redux/UPDATE_USER_REQUEST_SUCCESS";
export const UPDATE_USER_REQUEST_FAILURE = "redux/UPDATE_USER_REQUEST_FAILURE";
export const UPDATE_USER_REQUEST_END = "redux/UPDATE_USER_REQUEST_END";

export const RESET_PASSWORD_USER = "redux/RESET_PASSWORD_USER";
export const RESET_PASSWORD_USER_REQUEST = "redux/RESET_PASSWORD_USER_REQUEST";
export const RESET_PASSWORD_USER_REQUEST_SUCCESS = "redux/RESET_PASSWORD_USER_REQUEST_SUCCESS";
export const RESET_PASSWORD_USER_REQUEST_FAILURE = "redux/RESET_PASSWORD_USER_REQUEST_FAILURE";
export const RESET_PASSWORD_USER_REQUEST_END = "redux/RESET_PASSWORD_USER_REQUEST_END";

export const CHANGE_PROFILE_IMAGE_USER = "redux/CHANGE_PROFILE_IMAGE_USER";
export const CHANGE_PROFILE_IMAGE_USER_REQUEST = "redux/CHANGE_PROFILE_IMAGE_USER_REQUEST";
export const CHANGE_PROFILE_IMAGE_USER_REQUEST_SUCCESS = "redux/CHANGE_PROFILE_IMAGE_USER_REQUEST_SUCCESS";
export const CHANGE_PROFILE_IMAGE_USER_REQUEST_FAILURE = "redux/CHANGE_PROFILE_IMAGE_USER_REQUEST_FAILURE";
export const CHANGE_PROFILE_IMAGE_USER_REQUEST_END = "redux/CHANGE_PROFILE_IMAGE_USER_REQUEST_END";

export const DELETE_ISSUE = "redux/DELETE_ISSUE";
export const DELETE_ISSUE_REQUEST = "redux/DELETE_ISSUE_REQUEST";
export const DELETE_ISSUE_REQUEST_SUCCESS = "redux/DELETE_ISSUE_REQUEST_SUCCESS";
export const DELETE_ISSUE_REQUEST_FAILURE = "redux/DELETE_ISSUE_REQUEST_FAILURE";
export const DELETE_ISSUE_REQUEST_END = "redux/DELETE_ISSUE_REQUEST_END";

export const UPDATE_ISSUE = "redux/UPDATE_ISSUE";
export const UPDATE_ISSUE_REQUEST = "redux/UPDATE_ISSUE_REQUEST";
export const UPDATE_ISSUE_REQUEST_SUCCESS = "redux/UPDATE_ISSUE_REQUEST_SUCCESS";
export const UPDATE_ISSUE_REQUEST_FAILURE = "redux/UPDATE_ISSUE_REQUEST_FAILURE";
export const UPDATE_ISSUE_REQUEST_END = "redux/UPDATE_ISSUE_REQUEST_END";

export const DELETE_ISSUE_COMMENT = "redux/DELETE_ISSUE_COMMENT";
export const DELETE_ISSUE_COMMENT_REQUEST = "redux/DELETE_ISSUE_COMMENT_REQUEST";
export const DELETE_ISSUE_COMMENT_REQUEST_SUCCESS = "redux/DELETE_ISSUE_COMMENT_REQUEST_SUCCESS";
export const DELETE_ISSUE_COMMENT_REQUEST_FAILURE = "redux/DELETE_ISSUE_COMMENT_REQUEST_FAILURE";
export const DELETE_ISSUE_COMMENT_REQUEST_END = "redux/DELETE_ISSUE_COMMENT_REQUEST_END";

export const ADD_ISSUE = "redux/ADD_ISSUE";
export const ADD_ISSUE_REQUEST = "redux/ADD_ISSUE_REQUEST";
export const ADD_ISSUE_REQUEST_SUCCESS = "redux/ADD_ISSUE_REQUEST_SUCCESS";
export const ADD_ISSUE_REQUEST_FAILURE = "redux/ADD_ISSUE_REQUEST_FAILURE";
export const ADD_ISSUE_REQUEST_END = "redux/ADD_ISSUE_REQUEST_END";

export const DELETE_CATEGORY = "redux/DELETE_CATEGORY";
export const DELETE_CATEGORY_REQUEST = "redux/DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_REQUEST_SUCCESS = "redux/DELETE_CATEGORY_REQUEST_SUCCESS";
export const DELETE_CATEGORY_REQUEST_FAILURE = "redux/DELETE_CATEGORY_REQUEST_FAILURE";
export const DELETE_CATEGORY_REQUEST_END = "redux/DELETE_CATEGORY_REQUEST_END";

export const UPDATE_CATEGORY = "redux/UPDATE_CATEGORY";
export const UPDATE_CATEGORY_REQUEST = "redux/UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_REQUEST_SUCCESS = "redux/UPDATE_CATEGORY_REQUEST_SUCCESS";
export const UPDATE_CATEGORY_REQUEST_FAILURE = "redux/UPDATE_CATEGORY_REQUEST_FAILURE";
export const UPDATE_CATEGORY_REQUEST_END = "redux/UPDATE_CATEGORY_REQUEST_END";

export const ADD_CATEGORY = "redux/ADD_CATEGORY";
export const ADD_CATEGORY_REQUEST = "redux/ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_REQUEST_SUCCESS = "redux/ADD_CATEGORY_REQUEST_SUCCESS";
export const ADD_CATEGORY_REQUEST_FAILURE = "redux/ADD_CATEGORY_REQUEST_FAILURE";
export const ADD_CATEGORY_REQUEST_END = "redux/ADD_CATEGORY_REQUEST_END";

export const DELETE_QUESTION_ANSWER = "redux/DELETE_QUESTION_ANSWER";
export const DELETE_QUESTION_ANSWER_REQUEST = "redux/DELETE_QUESTION_ANSWER_REQUEST";
export const DELETE_QUESTION_ANSWER_REQUEST_SUCCESS = "redux/DELETE_QUESTION_ANSWER_REQUEST_SUCCESS";
export const DELETE_QUESTION_ANSWER_REQUEST_FAILURE = "redux/DELETE_QUESTION_ANSWER_REQUEST_FAILURE";
export const DELETE_QUESTION_ANSWER_REQUEST_END = "redux/DELETE_QUESTION_ANSWER_REQUEST_END";

export const DELETE_QUESTION = "redux/DELETE_QUESTION";
export const DELETE_QUESTION_REQUEST = "redux/DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_REQUEST_SUCCESS = "redux/DELETE_QUESTION_REQUEST_SUCCESS";
export const DELETE_QUESTION_REQUEST_FAILURE = "redux/DELETE_QUESTION_REQUEST_FAILURE";
export const DELETE_QUESTION_REQUEST_END = "redux/DELETE_QUESTION_REQUEST_END";

export const UPDATE_QUESTION = "redux/UPDATE_QUESTION";
export const UPDATE_QUESTION_REQUEST = "redux/UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_REQUEST_SUCCESS = "redux/UPDATE_QUESTION_REQUEST_SUCCESS";
export const UPDATE_QUESTION_REQUEST_FAILURE = "redux/UPDATE_QUESTION_REQUEST_FAILURE";
export const UPDATE_QUESTION_REQUEST_END = "redux/UPDATE_QUESTION_REQUEST_END";

export const ADD_ARCHIVE = "redux/ADD_ARCHIVE";
export const ADD_ARCHIVE_REQUEST = "redux/ADD_ARCHIVE_REQUEST";
export const ADD_ARCHIVE_REQUEST_SUCCESS = "redux/ADD_ARCHIVE_REQUEST_SUCCESS";
export const ADD_ARCHIVE_REQUEST_FAILURE = "redux/ADD_ARCHIVE_REQUEST_FAILURE";
export const ADD_ARCHIVE_REQUEST_END = "redux/ADD_ARCHIVE_REQUEST_END";

export const DELETE_ARCHIVE = "redux/DELETE_ARCHIVE";
export const DELETE_ARCHIVE_REQUEST = "redux/DELETE_ARCHIVE_REQUEST";
export const DELETE_ARCHIVE_REQUEST_SUCCESS = "redux/DELETE_ARCHIVE_REQUEST_SUCCESS";
export const DELETE_ARCHIVE_REQUEST_FAILURE = "redux/DELETE_ARCHIVE_REQUEST_FAILURE";
export const DELETE_ARCHIVE_REQUEST_END = "redux/DELETE_ARCHIVE_REQUEST_END";

export const GET_ARCHIVE_DETAIL = "redux/GET_ARCHIVE_DETAIL";
export const GET_ARCHIVE_DETAIL_REQUEST = "redux/GET_ARCHIVE_DETAIL_REQUEST";
export const GET_ARCHIVE_DETAIL_REQUEST_SUCCESS = "redux/GET_ARCHIVE_DETAIL_REQUEST_SUCCESS";
export const GET_ARCHIVE_DETAIL_REQUEST_FAILURE = "redux/GET_ARCHIVE_DETAIL_REQUEST_FAILURE";
export const GET_ARCHIVE_DETAIL_REQUEST_END = "redux/GET_ARCHIVE_DETAIL_REQUEST_END";

export const UPDATE_ARCHIVE = "redux/UPDATE_ARCHIVE";
export const UPDATE_ARCHIVE_REQUEST = "redux/UPDATE_ARCHIVE_REQUEST";
export const UPDATE_ARCHIVE_REQUEST_SUCCESS = "redux/UPDATE_ARCHIVE_REQUEST_SUCCESS";
export const UPDATE_ARCHIVE_REQUEST_FAILURE = "redux/UPDATE_ARCHIVE_REQUEST_FAILURE";
export const UPDATE_ARCHIVE_REQUEST_END = "redux/UPDATE_ARCHIVE_REQUEST_END";

export const ADD_ACTIVITIES = "redux/ADD_ACTIVITIES";
export const ADD_ACTIVITIES_REQUEST = "redux/ADD_ACTIVITIES_REQUEST";
export const ADD_ACTIVITIES_REQUEST_SUCCESS = "redux/ADD_ACTIVITIES_REQUEST_SUCCESS";
export const ADD_ACTIVITIES_REQUEST_FAILURE = "redux/ADD_ACTIVITIES_REQUEST_FAILURE";
export const ADD_ACTIVITIES_REQUEST_END = "redux/ADD_ACTIVITIES_REQUEST_END";

export const DELETE_ACTIVITIES = "redux/DELETE_ACTIVITIES";
export const DELETE_ACTIVITIES_REQUEST = "redux/DELETE_ACTIVITIES_REQUEST";
export const DELETE_ACTIVITIES_REQUEST_SUCCESS = "redux/DELETE_ACTIVITIES_REQUEST_SUCCESS";
export const DELETE_ACTIVITIES_REQUEST_FAILURE = "redux/DELETE_ACTIVITIES_REQUEST_FAILURE";
export const DELETE_ACTIVITIES_REQUEST_END = "redux/DELETE_ACTIVITIES_REQUEST_END";

export const UPDATE_ACTIVITIES = "redux/UPDATE_ACTIVITIES";
export const UPDATE_ACTIVITIES_REQUEST = "redux/UPDATE_ACTIVITIES_REQUEST";
export const UPDATE_ACTIVITIES_REQUEST_SUCCESS = "redux/UPDATE_ACTIVITIES_REQUEST_SUCCESS";
export const UPDATE_ACTIVITIES_REQUEST_FAILURE = "redux/UPDATE_ACTIVITIES_REQUEST_FAILURE";
export const UPDATE_ACTIVITIES_REQUEST_END = "redux/UPDATE_ACTIVITIES_REQUEST_END";

export const SEND_NOTIFICATION_USER = "redux/SEND_NOTIFICATION_USER";
export const SEND_NOTIFICATION_USER_REQUEST = "redux/SEND_NOTIFICATION_USER_REQUEST";
export const SEND_NOTIFICATION_USER_REQUEST_SUCCESS = "redux/SEND_NOTIFICATION_USER_REQUEST_SUCCESS";
export const SEND_NOTIFICATION_USER_REQUEST_FAILURE = "redux/SEND_NOTIFICATION_USER_REQUEST_FAILURE";
export const SEND_NOTIFICATION_USER_REQUEST_END = "redux/SEND_NOTIFICATION_USER_REQUEST_END";

export const GET_KURUMLAR_LIST = "redux/GET_KURUMLAR_LIST";
export const GET_KURUMLAR_LIST_REQUEST = "redux/GET_KURUMLAR_LIST_REQUEST";
export const GET_KURUMLAR_LIST_REQUEST_SUCCESS = "redux/GET_KURUMLAR_LIST_REQUEST_SUCCESS";
export const GET_KURUMLAR_LIST_REQUEST_FAILURE = "redux/GET_KURUMLAR_LIST_REQUEST_FAILURE";
export const GET_KURUMLAR_LIST_REQUEST_END = "redux/GET_KURUMLAR_LIST_REQUEST_END";

export const ADD_KURUM = "redux/ADD_KURUM";
export const ADD_KURUM_REQUEST = "redux/ADD_KURUM_REQUEST";
export const ADD_KURUM_REQUEST_SUCCESS = "redux/ADD_KURUM_REQUEST_SUCCESS";
export const ADD_KURUM_REQUEST_FAILURE = "redux/ADD_KURUM_REQUEST_FAILURE";
export const ADD_KURUM_REQUEST_END = "redux/ADD_KURUM_REQUEST_END";

export const DELETE_KURUM = "redux/DELETE_KURUM";
export const DELETE_KURUM_REQUEST = "redux/DELETE_KURUM_REQUEST";
export const DELETE_KURUM_REQUEST_SUCCESS = "redux/DELETE_KURUM_REQUEST_SUCCESS";
export const DELETE_KURUM_REQUEST_FAILURE = "redux/DELETE_KURUM_REQUEST_FAILURE";
export const DELETE_KURUM_REQUEST_END = "redux/DELETE_KURUM_REQUEST_END";

export const UPDATE_KURUM = "redux/UPDATE_KURUM";
export const UPDATE_KURUM_REQUEST = "redux/UPDATE_KURUM_REQUEST";
export const UPDATE_KURUM_REQUEST_SUCCESS = "redux/UPDATE_KURUM_REQUEST_SUCCESS";
export const UPDATE_KURUM_REQUEST_FAILURE = "redux/UPDATE_KURUM_REQUEST_FAILURE";
export const UPDATE_KURUM_REQUEST_END = "redux/UPDATE_KURUM_REQUEST_END";

export const POST_UPLOAD_IMAGE_OR_PDF = "redux/POST_UPLOAD_IMAGE_OR_PDF";
export const POST_UPLOAD_IMAGE_OR_PDF_REQUEST = "redux/POST_UPLOAD_IMAGE_OR_PDF_REQUEST";
export const POST_UPLOAD_IMAGE_OR_PDF_REQUEST_SUCCESS = "redux/POST_UPLOAD_IMAGE_OR_PDF_REQUEST_SUCCESS";
export const POST_UPLOAD_IMAGE_OR_PDF_REQUEST_FAILURE = "redux/POST_UPLOAD_IMAGE_OR_PDF_REQUEST_FAILURE";
export const POST_UPLOAD_IMAGE_OR_PDF_REQUEST_END = "redux/POST_UPLOAD_IMAGE_OR_PDF_REQUEST_END";

export const DELETE_ARCHIVE_ATTACHMENT = "redux/DELETE_ARCHIVE_ATTACHMENT";
export const DELETE_ARCHIVE_ATTACHMENT_REQUEST = "redux/DELETE_ARCHIVE_ATTACHMENT_REQUEST";
export const DELETE_ARCHIVE_ATTACHMENT_REQUEST_SUCCESS = "redux/DELETE_ARCHIVE_ATTACHMENT_REQUEST_SUCCESS";
export const DELETE_ARCHIVE_ATTACHMENT_REQUEST_FAILURE = "redux/DELETE_ARCHIVE_ATTACHMENT_REQUEST_FAILURE";
export const DELETE_ARCHIVE_ATTACHMENT_REQUEST_END = "redux/DELETE_ARCHIVE_ATTACHMENT_REQUEST_END";

export const DELETE_QUESTION_ATTACHMENT = "redux/DELETE_QUESTION_ATTACHMENT";
export const DELETE_QUESTION_ATTACHMENT_REQUEST = "redux/DELETE_QUESTION_ATTACHMENT_REQUEST";
export const DELETE_QUESTION_ATTACHMENT_REQUEST_SUCCESS = "redux/DELETE_QUESTION_ATTACHMENT_REQUEST_SUCCESS";
export const DELETE_QUESTION_ATTACHMENT_REQUEST_FAILURE = "redux/DELETE_QUESTION_ATTACHMENT_REQUEST_FAILURE";
export const DELETE_QUESTION_ATTACHMENT_REQUEST_END = "redux/DELETE_QUESTION_ATTACHMENT_REQUEST_END";

export const ADD_QUESTION_ATTACHMENT = "redux/ADD_QUESTION_ATTACHMENT";
export const ADD_QUESTION_ATTACHMENT_REQUEST = "redux/ADD_QUESTION_ATTACHMENT_REQUEST";
export const ADD_QUESTION_ATTACHMENT_REQUEST_SUCCESS = "redux/ADD_QUESTION_ATTACHMENT_REQUEST_SUCCESS";
export const ADD_QUESTION_ATTACHMENT_REQUEST_FAILURE = "redux/ADD_QUESTION_ATTACHMENT_REQUEST_FAILURE";
export const ADD_QUESTION_ATTACHMENT_REQUEST_END = "redux/ADD_QUESTION_ATTACHMENT_REQUEST_END";
