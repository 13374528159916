import { useState, useEffect } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import Grid from "@mui/material/Grid";


// Data
import dataTable from "./data/dataTable";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Rings } from 'react-loader-spinner'
import { useNavigate, NavLink } from "react-router-dom";
import {useSearchParams} from 'react-router-dom';

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [LOADER, SET_LOADER] = useState(true);
  const [DATA, SET_DATA] = useState([]);
  const [DATA_STATUS, SET_DATA_STATUS] = useState(false);
  const [COLUMN, SET_COLUMN] = useState([]);
  const [ROW, SET_ROW] = useState([]);

  useEffect(() => {
    if(props.ARCHIVE_LIST&&props.ARCHIVE_LIST.length>0&&LOADER&&props.CATEGORY_LIST.length>0){
      const d = dataTable({ARCHIVE:props.ARCHIVE_LIST,CATEGORY:props.CATEGORY_LIST});
      SET_DATA(d)
      SET_DATA_STATUS(true)
      SET_LOADER(false)
    }else {
      props.getCategory();
      props.getData();
      SET_LOADER(true)
      SET_DATA_STATUS(false)
    }
  }, [props.ARCHIVE_LIST]);

  useEffect(() => {
    if(props.CATEGORY_LIST&&props.CATEGORY_LIST.length>0){
    }else {
      props.getCategory();
    }
  }, [props.CATEGORY_LIST]);

  if(searchParams.has('delete_comment_id')){
    if(location.search.split("=")[1]>0){
      if (confirm('Bu veriyi silmek istiyor musunuz?') == true) {
        props.deleteData(searchParams.get('delete_comment_id'))
        history("/Kanunlar")
      } else {
        history("/Kanunlar")
      }
    }
  }
  useEffect(() => {
    if (props.DELETE_ARCHIVE_STATUS==1) {
      SET_LOADER(true)
    }else if(props.DELETE_ARCHIVE_STATUS==2){
      props.getData();
      SET_LOADER(false)
      alert('Veri başarıyla silindi')
    }else if(props.DELETE_ARCHIVE_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.DELETE_ISSUE_COMMENT_ERROR))
    }
  }, [props.DELETE_ARCHIVE_STATUS])


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
      <Grid container mb={6} spacing={1}>
        <Grid md={3} xl={10} >
        </Grid>
        <Grid md={3} xl={2} >
          <NavLink
          to={{ pathname: "/kanun/ekle"}} className="nav-link" rel="noopener noreferrer" >
            <SuiButton variant="gradient" color="info" fullWidth >
              Veri Ekle
            </SuiButton>
          </NavLink>
        </Grid>
      </Grid>
      <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {LOADER?
              <Rings/>
                :
              <Table columns={DATA.columns1} rows={DATA.rows2} />
              }
            </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { CATEGORY_LIST, ARCHIVE_LIST, ARCHIVE_LIST_LOADER, ARCHIVE_LIST_STATUS, DELETE_ARCHIVE_LOADER, DELETE_ARCHIVE_STATUS } = state.app;
  return { CATEGORY_LIST, ARCHIVE_LIST, ARCHIVE_LIST_LOADER, ARCHIVE_LIST_STATUS, DELETE_ARCHIVE_LOADER, DELETE_ARCHIVE_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  getData: () => {
    dispatch({
      type: $.GET_ARCHIVE_LIST
    });
  },
  getCategory: () => {
    dispatch({
      type: $.GET_CATEGORY_LIST
    });
  },
  deleteData: (ID) => {
    dispatch({
      type: $.DELETE_ARCHIVE,
      ID
    });
  },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
