import { useState, useEffect } from "react";
import React from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'
import Projects from "../dashboard/components/Projects"
import {useSearchParams} from 'react-router-dom';


// Data
import userlistData from "./data/data";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [CATEGORY, SET_CATEGORY] = useState([]);
  const [CATEGORY_NAME, SET_CATEGORY_NAME] = useState('');
  const [NAME, SET_NAME] = useState('');
  const [UST_KURUM_NAME, SET_UST_KURUM_NAME] = useState('');
  const [PARENT_ID, SET_PARENT_ID] = useState('');
  const [LOADER, SET_LOADER] = useState(false);
  const [IMAGE_SHOW, SET_IMAGE_SHOW] = useState(false);
  const [IMAGE_UPLOAD, SET_IMAGE_UPLOAD] = useState('');
  const [DELETE_CATEGORY_CONFIRM, SET_DELETE_CATEGORY_CONFIRM] = useState(false);
  const [OPEN, SET_OPEN] = useState(false);

  const openMenu = ({ currentTarget }) => SET_OPEN(currentTarget);
  const closeMenu = () => SET_OPEN(null);



  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={OPEN}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(OPEN)}
      onClose={closeMenu}
    >
      {props.KURUMLAR.map(item=>
      <MenuItem onClick={()=>{SET_UST_KURUM_NAME(item.NAME);SET_PARENT_ID(item.ID);SET_OPEN(false)}}>{item.NAME}</MenuItem>
      )}
      <MenuItem onClick={()=>{history('/Kurumlar/ekle');SET_OPEN(false)}}>+ Kurum Ekle</MenuItem>

    </Menu>
  );
  
  useEffect(() => {
    if(props.KURUMLAR[0]?.ID){
      console.log("redirect",redirect)
      let data = props.KURUMLAR.filter(item=>item.ID==searchParams.get('id'))[0];
      if(data?.ID>0){
        console.log("seçilen Kurum",data)
        SET_CATEGORY(data) 
        SET_NAME(data.NAME)        
        SET_UST_KURUM_NAME(data.UST_KURUM)        
        SET_PARENT_ID(data.PARENT_ID)        
      }else {
        alert('Böyle bir Kurum bulunmamaktadır.')
      }
    }else {
      props.getCategoryList();
    }
  }, [props.KURUMLAR]);

  useEffect(() => {
    if (props.DELETE_KURUM_STATUS==1) {
      SET_LOADER(true)
    }else if(props.DELETE_KURUM_STATUS==2){
      props.getCategoryList();
      SET_LOADER(false)
      alert('Kurum başarıyla silindi')
      history("/Kurumlar")
    }else if(props.DELETE_KURUM_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.DELETE_CATEGORY_ERROR))
    }
  }, [props.DELETE_KURUM_STATUS])
  
  useEffect(() => {
    if (DELETE_CATEGORY_CONFIRM) {
      let text = "Gerçekten silmek istiyor musunuz ?";
      if (confirm(text) == true) {
        props.deleteCategory(CATEGORY.ID)
      } else {
        SET_DELETE_CATEGORY_CONFIRM(false)
      }
    }
  }, [DELETE_CATEGORY_CONFIRM])
 
  useEffect(() => {
    if (props.UPDATE_KURUM_STATUS==1) {
      SET_LOADER(true)
    }else if(props.UPDATE_KURUM_STATUS==2){
      alert('Kurum güncellendi')
      SET_LOADER(false)
      props.getCategoryList();
    }else if(props.UPDATE_KURUM_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.UPDATE_KURUM_ERROR))
    }
  }, [props.UPDATE_KURUM_STATUS])
  
 
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      SET_IMAGE_SHOW(true)
      SET_IMAGE_UPLOAD(img)
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {CATEGORY?.ID>0?
      <SuiBox p={9}>
        <SuiBox component="form" role="form">
          <SuiBox mb={9} ml={0.5}>
            <Grid container spacing={1}>
              <Grid md={12} xl={12}>
                
              </Grid>
              <Grid md={9} xl={9}>

                </Grid>
                <Grid md={3} xl={2} >
                  <SuiButton color="info" disabled={LOADER} fullWidth onClick={()=>SET_DELETE_CATEGORY_CONFIRM(true)}>
                    {LOADER?
                    <Oval height = "15" width = "15"/>
                    :
                    'Kurum Sil'
                    }
                  </SuiButton>
                </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Kurum İsmi
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Kurum İsmi"
                    value={NAME}
                    onChange={(e) => SET_NAME(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox mb={2}>
                {UST_KURUM_NAME?
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography variant="h5">
                    Seçilen Üst Kurum: {UST_KURUM_NAME}
                  </SuiTypography>
                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={openMenu}> 
                    Üst Kurum Seç
                  </SuiButton>
                </SuiBox>
                :
                <SuiButton
                  variant="gradient"
                  color="info"
                  onClick={openMenu}> 
                  Üst Kurum Seç
                </SuiButton>
                }
                {renderMenu}
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={LOADER}
              onClick={()=>props.updateCategory(CATEGORY.ID,NAME,PARENT_ID)}
            >
              {LOADER?
              <Oval height = "15" width = "15"/>
              :
              'Güncelle'
              }
            </SuiButton>
          </SuiBox>

        </SuiBox>

      </SuiBox>
      :
      <Oval height = "15" width = "15"/>
      }
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { KURUMLAR, KURUMLAR_LOADER, KURUMLAR_STATUS, DELETE_CATEGORY_LOADER, DELETE_KURUM_STATUS, UPDATE_KURUM_LOADER, UPDATE_KURUM_STATUS } = state.app;
  return { KURUMLAR, KURUMLAR_LOADER, KURUMLAR_STATUS, DELETE_CATEGORY_LOADER, DELETE_KURUM_STATUS, UPDATE_KURUM_LOADER, UPDATE_KURUM_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  getCategoryList: () => {
    dispatch({
      type: $.GET_KURUMLAR_LIST
    });
  },
  deleteCategory: (ID) => {
    dispatch({
      type: $.DELETE_KURUM,
      ID
    });
  },
  updateCategory: (ID, NAME,PARENT_ID) => {
    dispatch({
      type: $.UPDATE_KURUM,
      ID,
      PAYLOAD: {NAME,PARENT_ID}
    });
  },
  
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
