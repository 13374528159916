import { useState, useEffect } from "react";
import React from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'
import Projects from "../dashboard/components/Projects"
import {useSearchParams} from 'react-router-dom';


// Data
import userlistData from "./data/data";
import YorumTable from "./data/yorumlar";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Dashboard(props) {
  let history = useNavigate();
  const [IMAGE_SHOW, SET_IMAGE_SHOW] = useState(false);
  const [IMAGE_UPLOAD, SET_IMAGE_UPLOAD] = useState('');
  const [LOADER, SET_LOADER] = useState(false);
  const [ISSUE_HEADER, SET_ISSUE_HEADER] = useState('');
  const [ISSUE_CONTENT, SET_ISSUE_CONTENT] = useState('');
  const [IS_COMMENT, SET_IS_COMMENT] = useState(1);
  const [COMMENTS_HAVE, SET_COMMENTS_HAVE] = useState(false);
  const [OPEN, SET_OPEN] = useState(false);
  const [SELECTED_CATEGORY, SET_SELECTED_CATEGORY] = useState(false);
  const [CATEGORY_ID, SET_CATEGORY_ID] = useState(0);
  
  const openMenu = ({ currentTarget }) => SET_OPEN(currentTarget);
  const closeMenu = () => SET_OPEN(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      SET_IMAGE_SHOW(true)
      SET_IMAGE_UPLOAD(img)
    }
  };
  
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={OPEN}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(OPEN)}
      onClose={closeMenu}
    >
      {props.CATEGORY_LIST.map(item=>
      <MenuItem onClick={()=>{SET_SELECTED_CATEGORY(item.CATEGORY_NAME);SET_CATEGORY_ID(item.ID);SET_OPEN(false)}}>{item.CATEGORY_NAME}</MenuItem>
      )}
      <MenuItem onClick={()=>{history('/kategori/ekle');SET_OPEN(false)}}>+ Kategori Ekle</MenuItem>

    </Menu>
  );

  useEffect(() => {
    if (props.ADD_ISSUE_STATUS==1) {
      SET_LOADER(true)
    }else if(props.ADD_ISSUE_STATUS==2){
      props.getIssueList();
      SET_LOADER(false)
      alert('Haber başarıyla eklendi')
      history("/haberler")
    }else if(props.ADD_ISSUE_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.ADD_ISSUE_ERROR))
    }
  }, [props.ADD_ISSUE_STATUS])
  
  useEffect(() => {
    if(props.CATEGORY_LIST&&props.CATEGORY_LIST.length>0){
    }else {
      props.getCategory();
    }
  }, [props.CATEGORY_LIST]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox p={9}>
        <SuiBox component="form" role="form">
          <SuiBox mb={9} ml={0.5}>
            <Grid container spacing={1}>
            <Grid container spacing={1}>
              <Grid item md={12} xl={6}>

              </Grid>
              <Grid item md={12} xl={4}>
              </Grid>
            </Grid>
              <Grid md={9} xl={9}>
                  <SuiBox
                    component="img"
                    src={IMAGE_SHOW?URL.createObjectURL(IMAGE_UPLOAD):apiUrl + "image/show/default.png"}
                    height="200px"
                  />
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Haber Fotoğrafı
                    </SuiTypography>
                    <input type="file" disabled={LOADER} className="mb-2" name="myImage"  onChange={(event) => onImageChange(event)}/>
                  </SuiBox>
                </Grid>
                <Grid md={3} xl={2} >
                </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Haber Başlığı
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Haber Başlığı"
                    value={ISSUE_HEADER}
                    onChange={(e) => SET_ISSUE_HEADER(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={6}>
              {SELECTED_CATEGORY?
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography variant="h5">
                    Seçilen Kategori: {SELECTED_CATEGORY}
                  </SuiTypography>
                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={openMenu}> 
                    Kategori Seç
                  </SuiButton>
                </SuiBox>
                :
                <SuiButton
                  variant="gradient"
                  color="info"
                  onClick={openMenu}> 
                  Kategori Seç
                </SuiButton>
                }
                {renderMenu}
              </Grid>
              <Grid item xs={12} sm={12} xl={12}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Haber İçeriği
                    </SuiTypography>
                  </SuiBox>
                   <CKEditor
                    editor={ ClassicEditor }
                    data={ISSUE_CONTENT}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                        SET_ISSUE_CONTENT(data)
                    } }
                />
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid md={12} xl={6}>
              </Grid>
              <Grid md={12} xl={6}>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={7}>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCardNew
              title={{ text: "Haber Yorum Durumu" }}
              count={IS_COMMENT==1?'Yorum Aktif':'Yorum Kapalı'}
              percentage={{ color: "success"}}
              action={()=> SET_IS_COMMENT(prompt(IS_COMMENT==1?'Kapatmak için 0 yazınız':'Açmak için 1 yazınız'))}
              icon={{
                color: "info",
                component: "edit",
              }}
              />
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
            </Grid>
          </Grid>

          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={LOADER}
              onClick={()=>props.addIssue({ISSUE_IMAGE:IMAGE_SHOW?IMAGE_UPLOAD:false,ISSUE_HEADER,ISSUE_CONTENT,IS_COMMENT,CATEGORY_ID})}
            >
              {LOADER?
              <Oval height = "15" width = "15"/>
              :
              'Ekle'
              }
            </SuiButton>
          </SuiBox>

        </SuiBox>

      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { ADD_ISSUE_LOADER, ADD_ISSUE_STATUS, CATEGORY_LIST } = state.app;
  return { ADD_ISSUE_LOADER, ADD_ISSUE_STATUS, CATEGORY_LIST };
};

const mapDispatchToProps = (dispatch, props) => ({
  addIssue: (payload) => {
    dispatch({
      type: $.ADD_ISSUE,
      payload
    });
  },
  getIssueList: () => {
    dispatch({
      type: $.GET_ISSUE_LIST
    });
  },
  getCategory: () => {
    dispatch({
      type: $.GET_CATEGORY_LIST
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
