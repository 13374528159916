import { useState, useEffect } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";


// Data
import data from "./data/data";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {useSearchParams} from 'react-router-dom';
import { Oval } from 'react-loader-spinner'
import {apiUrl} from "../../config/index";

function Dashboard(props) {
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [LOADER, SET_LOADER] = useState(false);
  const [QUESTION, SET_QUESTION] = useState([]);
  const [QUESTION_CONTENT, SET_QUESTION_CONTENT] = useState('');
  const [QUESTION_HEADER, SET_QUESTION_HEADER] = useState('');
  const [ATTACHMENTS, SET_ATTACHMENTS] = useState([]);
  
  
  useEffect(() => {
    if(props.QUESTION_LIST[0]?.ID){
      let data = props.QUESTION_LIST.filter(item=>item.ID==searchParams.get('id'))[0];
      if(data?.ID>0){
        SET_QUESTION(data) 
        SET_QUESTION_HEADER(data.QUESTION_HEADER) 
        SET_QUESTION_CONTENT(data.QUESTION_CONTENT)
        SET_ATTACHMENTS(data.FILE)
      }else {
        alert('Böyle bir soru bulunmamaktadır.')
      }
    }else {
      props.data();
    }
  }, [props.QUESTION_LIST]);


  useEffect(() => {
    if (props.UPDATE_QUESTION_STATUS==1) {
      SET_LOADER(true)
    }else if(props.UPDATE_QUESTION_STATUS==2&&props.UPLOAD_QUESTION_ATTACH_COUNTER==0){
      props.data();
      SET_LOADER(false)
      alert('Soru başarıyla güncellendi')
    }else if(props.UPDATE_QUESTION_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.UPDATE_QUESTION_ERROR))
    }
  }, [props.UPDATE_QUESTION_STATUS])


  useEffect(() => {
    if (props.UPLOAD_QUESTION_ATTACH_COUNTER>0) {
      console.log('counter',props.UPLOAD_QUESTION_ATTACH_COUNTER)
      SET_LOADER(true)
    }else if(props.UPLOAD_QUESTION_ATTACH_COUNTER==0&&props.UPLOAD_QUESTION_ATTACH_STATUS==2&&LOADER){
      SET_LOADER(false)
      alert('Soru başarıyla güncellendi.')
    }
    if(props.UPLOAD_QUESTION_ATTACH_STATUS==3){
      alert('Yüklenirken hata oluştu'+props.UPLOAD_QUESTION_ATTACH_ERROR)
    }
  }, [props.UPLOAD_QUESTION_ATTACH_COUNTER])

  function handleClick(e,i) {
    if(e?.INSERT_DATE){
      props.deleteQuestionAttachment(QUESTION.ID,e.ID)
    }else {
      let array = ATTACHMENTS.filter(item => item.lastModified != e.lastModified);
      SET_ATTACHMENTS(array)
    }
  }

  const { columns1, rows2 } = data(props.QUESTION_LIST);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container spacing={1}>
          <Grid md={12} xl={12}>
            <Grid md={6} xl={6}>
              <SuiBox mb={2}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Soru Başlığı
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  type="text"
                  placeholder="Soru Başlığı"
                  value={QUESTION_HEADER}
                  onChange={(e) => SET_QUESTION_HEADER(e.target.value)}
                  />
              </SuiBox>
            </Grid>
          </Grid>
          <Grid md={12} xl={12} >
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Soru İçerği
                </SuiTypography>
              </SuiBox>
              <SuiInput
                type="text"
                placeholder="Soru İçerği"
                value={QUESTION_CONTENT}
                onChange={(e) => SET_QUESTION_CONTENT(e.target.value)}

              />
            </SuiBox>
          </Grid>
          <Grid item md={12} xl={12}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Ekler
                    </SuiTypography>
                  </SuiBox>
                    <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf" disabled={LOADER} className="mb-2" name="myImage"  onChange={(event) => SET_ATTACHMENTS([...ATTACHMENTS, event.target.files[0]])}/>
                    <SuiBox mb={1} mt={3} ml={3}>
                    {ATTACHMENTS.map((item,index)=>
                    item?.INSERT_DATE?
                    item.FILE_TYPE==1?
                        <SuiBox>
                          <a target="_blank" href={apiUrl+'image/showQuestionFile/'+item.FILE_NAME} style={{marginLeft:30}}>PDF İsmi: {item.FILE_NAME.slice(0,40)}</a>
                          <a href="#" onClick={()=>handleClick(item,index)} id={index} style={{marginLeft:25, color:'red', fontSize:16}}>Sil</a>
                        </SuiBox>
                      :
                        <SuiBox>
                          <a target="_blank" href={apiUrl+'image/showQuestionImage/'+item.FILE_NAME} style={{marginLeft:30}}>Resim İsmi: {item.FILE_NAME.slice(0,40)}</a>
                          <a href="#" onClick={()=>handleClick(item,index)} id={index} style={{marginLeft:25, color:'red', fontSize:16}}>Sil</a>
                        </SuiBox>
                    :
                    item.type=='application/pdf'?
                    <SuiBox>
                      <a target="_blank" href={URL.createObjectURL(new Blob([item], {"type":"application/pdf"}))} style={{marginLeft:30}}>PDF İsmi: {item.name.slice(0,40)}</a>
                      <a href="#" onClick={()=>handleClick(item,index)} id={index} style={{marginLeft:25, color:'red', fontSize:16}}>Sil</a>
                    </SuiBox>
                    :
                      <SuiBox>
                        <a target="_blank" href={URL.createObjectURL(item)} style={{marginLeft:30}}>Resim İsmi: {item.name.slice(0,40)}</a>
                        <a href="#" onClick={()=>handleClick(item,index)} id={index} style={{marginLeft:25, color:'red', fontSize:16}}>Sil</a>
                      </SuiBox>
                    )}
                      </SuiBox>
                </SuiBox>
              </Grid>
          <Grid md={12} xl={12} >
            <SuiBox mt={4} mb={1}>
              <SuiButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={LOADER}
                onClick={()=>props.updateQuestion(QUESTION.ID,ATTACHMENTS.filter(item=>item?.type).length,ATTACHMENTS.filter(item=>item?.type),{QUESTION_HEADER,QUESTION_CONTENT})}
              >
                {LOADER?
                <Oval height = "15" width = "15"/>
                :
                'Güncelle'
                }
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { QUESTION_LIST, QUESTION_LIST_LOADER, QUESTION_LIST_STATUS, UPDATE_QUESTION_LOADER, UPDATE_QUESTION_STATUS, UPLOAD_QUESTION_ATTACH_STATUS, UPLOAD_QUESTION_ATTACH_COUNTER, UPLOAD_QUESTION_ATTACH_ERROR } = state.app;
  return { QUESTION_LIST, QUESTION_LIST_LOADER, QUESTION_LIST_STATUS, UPDATE_QUESTION_LOADER, UPDATE_QUESTION_STATUS, UPLOAD_QUESTION_ATTACH_STATUS, UPLOAD_QUESTION_ATTACH_COUNTER, UPLOAD_QUESTION_ATTACH_ERROR };
};

const mapDispatchToProps = (dispatch, props) => ({
  data: () => {
    dispatch({
      type: $.GET_QUESTION_LIST
    });
  },
  deleteQuestion: (ID) => {
    dispatch({
      type: $.DELETE_QUESTION,
      ID
    });
  },
  
  updateQuestion: (ID, EK_DURUM, EKLER, payload) => {
    dispatch({
      type: $.UPDATE_QUESTION,
      EK_DURUM, 
      EKLER,
      ID,
      payload
    });
  },
  deleteQuestionAttachment: (QUESTION_ID, ATTACHMENT_ID) => {
    dispatch({
      type: $.DELETE_QUESTION_ATTACHMENT,
      QUESTION_ID, 
      ATTACHMENT_ID
    });
  },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
