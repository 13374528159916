import { takeLatest, takeEvery, call, put, select } from "redux-saga/effects";
import * as $ from "../action";
import { apiUrl, authHeader, onlyAuthorization } from "../../config";
import axios from "axios";
import store from '../store';

export function* handler() {
  yield takeEvery($.GET_LOGIN, FUNC_GET_LOGIN);
  yield takeEvery($.GET_SUMMARY, FUNC_GET_SUMMARY);
  yield takeEvery($.GET_USER_LIST, FUNC_GET_USER_LIST);
  yield takeEvery($.GET_QUESTION_LIST, FUNC_GET_QUESTION_LIST); 
  yield takeEvery($.GET_ISSUE_LIST, FUNC_GET_ISSUE_LIST); 
  yield takeEvery($.GET_ARCHIVE_LIST, FUNC_GET_ARCHIVE_LIST); 
  yield takeEvery($.GET_ACTIVITIES_LIST, FUNC_GET_ACTIVITIES_LIST); 
  yield takeEvery($.GET_CATEGORY_LIST, FUNC_GET_CATEGORY_LIST); 
  yield takeEvery($.GET_CONFIG, FUNC_GET_CONFIG); 
  yield takeEvery($.EDIT_CONFIG, FUNC_EDIT_CONFIG);   
  yield takeEvery($.DELETE_USER, FUNC_DELETE_USER);   
  yield takeEvery($.RESET_PASSWORD_USER, FUNC_RESET_PASSWORD_USER); 
  yield takeEvery($.UPDATE_USER, FUNC_UPDATE_USER); 
  yield takeEvery($.CHANGE_PROFILE_IMAGE_USER, FUNC_CHANGE_PROFILE_IMAGE_USER); 
  yield takeEvery($.DELETE_ISSUE, FUNC_DELETE_ISSUE);  
  yield takeEvery($.UPDATE_ISSUE, FUNC_UPDATE_ISSUE);  
  yield takeEvery($.DELETE_ISSUE_COMMENT, FUNC_DELETE_ISSUE_COMMENT);  
  yield takeEvery($.ADD_ISSUE, FUNC_ADD_ISSUE);  
  yield takeEvery($.DELETE_CATEGORY, FUNC_DELETE_CATEGORY);  
  yield takeEvery($.UPDATE_CATEGORY, FUNC_UPDATE_CATEGORY);
  yield takeEvery($.ADD_CATEGORY, FUNC_ADD_CATEGORY);
  yield takeEvery($.DELETE_QUESTION_ANSWER, FUNC_DELETE_QUESTION_ANSWER);
  yield takeEvery($.DELETE_QUESTION, FUNC_DELETE_QUESTION);
  yield takeEvery($.UPDATE_QUESTION, FUNC_UPDATE_QUESTION);
  yield takeEvery($.ADD_ARCHIVE, FUNC_ADD_ARCHIVE);
  yield takeEvery($.DELETE_ARCHIVE, FUNC_DELETE_ARCHIVE);
  yield takeEvery($.GET_ARCHIVE_DETAIL, FUNC_GET_ARCHIVE_DETAIL);
  yield takeEvery($.UPDATE_ARCHIVE, FUNC_UPDATE_ARCHIVE);
  yield takeEvery($.ADD_ACTIVITIES, FUNC_ADD_ACTIVITIES);
  yield takeEvery($.DELETE_ACTIVITIES, FUNC_DELETE_ACTIVITIES);
  yield takeEvery($.UPDATE_ACTIVITIES, FUNC_UPDATE_ACTIVITIES);
  yield takeEvery($.SEND_NOTIFICATION_USER, FUNC_SEND_NOTIFICATION_USER);
  yield takeEvery($.GET_KURUMLAR_LIST, FUNC_GET_KURUMLAR_LIST);  
  yield takeEvery($.ADD_KURUM, FUNC_ADD_KURUM);
  yield takeEvery($.DELETE_KURUM, FUNC_DELETE_KURUM);
  yield takeEvery($.UPDATE_KURUM, FUNC_UPDATE_KURUM);  
  yield takeEvery($.POST_UPLOAD_IMAGE_OR_PDF, FUNC_POST_UPLOAD_IMAGE_OR_PDF);  
  yield takeEvery($.DELETE_ARCHIVE_ATTACHMENT, FUNC_DELETE_ARCHIVE_ATTACHMENT);  
  yield takeEvery($.DELETE_QUESTION_ATTACHMENT, FUNC_DELETE_QUESTION_ATTACHMENT);  
  yield takeEvery($.ADD_QUESTION_ATTACHMENT, FUNC_ADD_QUESTION_ATTACHMENT);    
}

function* FUNC_GET_LOGIN(action) {
  try {
    yield put({
      type: $.GET_LOGIN_REQUEST,
    });
    const response = yield axios.post(apiUrl + "auth/login",action.payload)
    localStorage.setItem("token", JSON.stringify(response.data.accessToken));
    if (response.data.accessToken) {
      yield put({
        type: $.GET_LOGIN_REQUEST_SUCCESS,
        PAYLOAD: response.data.rows[0],
      });
    } else {
      console.log("fail geldi")
      yield put({
        type: $.GET_LOGIN_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    console.log("errorr geldi :",error)
    yield put({
      type: $.GET_LOGIN_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_LOGIN_REQUEST_END,
    });
  }
}

function* FUNC_GET_SUMMARY(action) {
  try {
    yield put({
      type: $.GET_SUMMARY_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/summary/list", authHeader())
    if (response.data) {
      yield put({
        type: $.GET_SUMMARY_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_SUMMARY_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_SUMMARY_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_SUMMARY_REQUEST_END,
    });
  }
}

function* FUNC_GET_USER_LIST(action) {
  try {
    yield put({
      type: $.GET_USER_LIST_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/user/list", authHeader())
    if (response.data) {
      console.log("user list başarılı",response.data)
      yield put({
        type: $.GET_USER_LIST_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_USER_LIST_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_USER_LIST_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_USER_LIST_REQUEST_END,
    });
  }
}

function* FUNC_GET_QUESTION_LIST(action) {
  try {
    yield put({
      type: $.GET_QUESTION_LIST_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/question/list", authHeader())
    if (response.data) {
      console.log("QUESTION list başarılı",response.data)
      yield put({
        type: $.GET_QUESTION_LIST_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_QUESTION_LIST_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_QUESTION_LIST_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_QUESTION_LIST_REQUEST_END,
    });
  }
}


function* FUNC_GET_ISSUE_LIST(action) {
  try {
    yield put({
      type: $.GET_ISSUE_LIST_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/issue/list", authHeader())
    if (response.data) {
      console.log("ISSUE list başarılı",response.data)
      yield put({
        type: $.GET_ISSUE_LIST_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_ISSUE_LIST_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_ISSUE_LIST_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_ISSUE_LIST_REQUEST_END,
    });
  }
}


function* FUNC_GET_ARCHIVE_LIST(action) {
  try {
    yield put({
      type: $.GET_ARCHIVE_LIST_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/archive/list", authHeader())
    if (response.data) {
      console.log("ARCHIVE list başarılı",response.data)
      yield put({
        type: $.GET_ARCHIVE_LIST_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_ARCHIVE_LIST_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_ARCHIVE_LIST_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_ARCHIVE_LIST_REQUEST_END,
    });
  }
}


function* FUNC_GET_ACTIVITIES_LIST(action) {
  try {
    yield put({
      type: $.GET_ACTIVITIES_LIST_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/activities/list", authHeader())
    if (response.data) {
      console.log("ACTIVITIES list başarılı",response.data)
      yield put({
        type: $.GET_ACTIVITIES_LIST_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_ACTIVITIES_LIST_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_ACTIVITIES_LIST_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_ACTIVITIES_LIST_REQUEST_END,
    });
  }
}


function* FUNC_GET_CATEGORY_LIST(action) {
  try {
    yield put({
      type: $.GET_CATEGORY_LIST_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/category/list", authHeader())
    if (response.data) {
      console.log("CATEGORY list başarılı",response.data)
      yield put({
        type: $.GET_CATEGORY_LIST_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_CATEGORY_LIST_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_CATEGORY_LIST_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_CATEGORY_LIST_REQUEST_END,
    });
  }
}

function* FUNC_GET_CONFIG(action) {
  try {
    yield put({
      type: $.GET_CONFIG_REQUEST,
    });
    const response = yield axios.get(apiUrl + "c/version", authHeader())
    if (response.data) {
      console.log("config list başarılı",response.data)
      yield put({
        type: $.GET_CONFIG_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_CONFIG_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_CONFIG_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_CONFIG_REQUEST_END,
    });
  }
}

function* FUNC_EDIT_CONFIG(action) {
  try {
    yield put({
      type: $.EDIT_CONFIG_REQUEST,
    });
    const response = yield axios.post(apiUrl + "dashboard/common/version/edit",action.payload, authHeader())
    if (response.data) {
      console.log("config edit başarılı",response.data)
      yield put({
        type: $.EDIT_CONFIG_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.EDIT_CONFIG_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.EDIT_CONFIG_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.EDIT_CONFIG_REQUEST_END,
    });
  }
}

function* FUNC_DELETE_USER(action) {
  try {
    yield put({
      type: $.DELETE_USER_REQUEST,
    });
    const response = yield axios.delete(apiUrl + "dashboard/user/delete/"+action.ID, authHeader())
    if (response.data) {
      console.log("user delete edit başarılı",response.data)
      yield put({
        type: $.DELETE_USER_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_USER_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_USER_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_USER_REQUEST_END,
    });
  }
}

function* FUNC_RESET_PASSWORD_USER(action){
  try {
    yield put({
      type: $.RESET_PASSWORD_USER_REQUEST,
    });
    const response = yield axios.post(apiUrl + "dashboard/user/passwordRenew/"+action.ID,action.payload, authHeader())
    if (response.data) {
      console.log("user password renew başarılı",response.data)
      yield put({
        type: $.RESET_PASSWORD_USER_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.RESET_PASSWORD_USER_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.RESET_PASSWORD_USER_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.RESET_PASSWORD_USER_REQUEST_END,
    });
  }
}

function* FUNC_UPDATE_USER(action){
  try {
    yield put({
      type: $.UPDATE_USER_REQUEST,
    });
    console.log("payload",action.payload)
    const response = yield axios.post(apiUrl + "dashboard/user/update/"+action.ID, action.payload, authHeader())
    if (response.data) {
      console.log("user update başarılı",response.data)
      yield put({
        type: $.UPDATE_USER_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.UPDATE_USER_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.UPDATE_USER_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.UPDATE_USER_REQUEST_END,
    });
  }
}

function* FUNC_CHANGE_PROFILE_IMAGE_USER(action){
  try {
    yield put({
      type: $.CHANGE_PROFILE_IMAGE_USER_REQUEST,
    });
    const formData = new FormData();
    formData.append("myImage", action.PROFILE_IMAGE);
    const user = JSON.parse(localStorage.getItem("token"));
    const config = {
      headers: {
        'Accept': 'application/json',
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      },
    };
    const response = yield axios.post(apiUrl + "dashboard/user/upload/"+action.ID, formData, {headers: {
      'Accept': 'application/json',
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user}`,
    }});
    console.log("user update",response.data)
    if (response.data) {
      console.log("user update başarılı",response.data)
      yield put({
        type: $.CHANGE_PROFILE_IMAGE_USER_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.CHANGE_PROFILE_IMAGE_USER_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    console.log("image upload error",error)
    yield put({
      type: $.CHANGE_PROFILE_IMAGE_USER_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.CHANGE_PROFILE_IMAGE_USER_REQUEST_END,
    });
  }
}

function* FUNC_DELETE_ISSUE(action){
  try {
    yield put({
      type: $.DELETE_ISSUE_REQUEST,
    });
    const response = yield axios.delete(apiUrl + "dashboard/issue/delete/"+action.ID, authHeader())
    if (response.data) {
      console.log("user delete edit başarılı",response.data)
      yield put({
        type: $.DELETE_ISSUE_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_ISSUE_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_ISSUE_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_ISSUE_REQUEST_END,
    });
  }
}

function* FUNC_UPDATE_ISSUE(action){
  try {
    yield put({
      type: $.UPDATE_ISSUE_REQUEST,
    });
    console.log("payload",action)
    if(action.IMAGE){
      console.log("image var işlem yapılacak")
      const formData = new FormData();
      formData.append("myImage", action.IMAGE);
      const user = JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          'Accept': 'application/json',
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${user}`,
        },
      };
      const response = yield axios.post(apiUrl + "dashboard/issue/upload", formData, {headers: {
        'Accept': 'application/json',
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      }});
      console.log("dönen response",response.data)
      action.PAYLOAD.ISSUE_IMAGE=response.data.NAME
    }else {
      console.log("image yok koçum")
    }
    const response = yield axios.post(apiUrl + "dashboard/issue/update/"+action.ID, action.PAYLOAD, authHeader())
    if (response.data) {
      console.log("user update başarılı",response.data)
      yield put({
        type: $.UPDATE_ISSUE_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.UPDATE_ISSUE_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.UPDATE_ISSUE_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.UPDATE_ISSUE_REQUEST_END,
    });
  }
}

function* FUNC_DELETE_ISSUE_COMMENT(action){
  try {
    yield put({
      type: $.DELETE_ISSUE_COMMENT_REQUEST,
    });
    const response = yield axios.delete(apiUrl + "dashboard/issue/delete_comment/"+action.ID, authHeader())
    if (response.data) {
      console.log("user delete edit başarılı",response.data)
      yield put({
        type: $.DELETE_ISSUE_COMMENT_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_ISSUE_COMMENT_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_ISSUE_COMMENT_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_ISSUE_COMMENT_REQUEST_END,
    });
  }
}

function* FUNC_ADD_ISSUE(action){
  try {
    yield put({
      type: $.ADD_ISSUE_REQUEST,
    });
    if(action.payload.ISSUE_IMAGE){
      console.log("image var işlem yapılacak")
      const formData = new FormData();
      formData.append("myImage", action.payload.ISSUE_IMAGE);
      const user = JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          'Accept': 'application/json',
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${user}`,
        },
      };
      const response = yield axios.post(apiUrl + "dashboard/issue/upload", formData, {headers: {
        'Accept': 'application/json',
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      }});
      console.log("dönen response",response.data)
      action.payload.ISSUE_IMAGE=response.data.NAME
    }else {
      console.log("image yok koçum")
    }
    console.log("payload",action.payload)
    const response = yield axios.post(apiUrl + "dashboard/issue/insert",action.payload, authHeader())
    if (response.data) {
      console.log("issue insert başarılı",response.data)
      yield put({
        type: $.ADD_ISSUE_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.ADD_ISSUE_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.ADD_ISSUE_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.ADD_ISSUE_REQUEST_END,
    });
  }
}

function* FUNC_DELETE_CATEGORY(action){
  try {
    yield put({
      type: $.DELETE_CATEGORY_REQUEST,
    });
    const response = yield axios.delete(apiUrl + "dashboard/category/delete/"+action.ID, authHeader())
    if (response.data) {
      console.log("category delete edit başarılı",response.data)
      yield put({
        type: $.DELETE_CATEGORY_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_CATEGORY_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_CATEGORY_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_CATEGORY_REQUEST_END,
    });
  }
}

function* FUNC_UPDATE_CATEGORY(action){
  try {
    yield put({
      type: $.UPDATE_CATEGORY_REQUEST,
    });
    console.log("veri geldi",action.payload)
    if(action.IMAGE){
      console.log("image var işlem yapılacak")
      const formData = new FormData();
      formData.append("myImage", action.payload.CATEGORY_IMAGE);
      const user = JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          'Accept': 'application/json',
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${user}`,
        },
      };
      const response = yield axios.post(apiUrl + "dashboard/category/upload", formData, {headers: {
        'Accept': 'application/json',
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      }});
      console.log("dönen response",response.data)
      action.payload.CATEGORY_IMAGE=response.data.NAME
    }else {
      console.log("image yok koçum")
    }
    console.log("payload",action.payload)
    const response = yield axios.post(apiUrl + "dashboard/category/update/"+action.ID,action.payload, authHeader())
    if (response.data) {
      console.log("CATEGORY update başarılı",response.data)
      yield put({
        type: $.UPDATE_CATEGORY_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.UPDATE_CATEGORY_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.UPDATE_CATEGORY_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.UPDATE_CATEGORY_REQUEST_END,
    });
  }
}


function* FUNC_ADD_CATEGORY(action){
  try {
    yield put({
      type: $.ADD_CATEGORY_REQUEST,
    });
    console.log("veri geldi",action.payload)
    if(action.IMAGE){
      console.log("image var işlem yapılacak")
      const formData = new FormData();
      formData.append("myImage", action.payload.CATEGORY_IMAGE);
      const user = JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          'Accept': 'application/json',
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${user}`,
        },
      };
      const response = yield axios.post(apiUrl + "dashboard/category/upload", formData, {headers: {
        'Accept': 'application/json',
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      }});
      console.log("dönen response",response.data)
      action.payload.CATEGORY_IMAGE=response.data.NAME
    }else {
      console.log("image yok koçum")
    }
    console.log("payload",action.payload,apiUrl + "dashboard/category/insert")
    const response = yield axios.post(apiUrl + "dashboard/category/insert",action.payload, authHeader())
    if (response.data) {
      console.log("CATEGORY insert başarılı",response.data)
      yield put({
        type: $.ADD_CATEGORY_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.ADD_CATEGORY_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.ADD_CATEGORY_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.ADD_CATEGORY_REQUEST_END,
    });
  }
}


function* FUNC_DELETE_QUESTION_ANSWER(action){
  try {
    yield put({
      type: $.DELETE_QUESTION_ANSWER_REQUEST,
    });
    const response = yield axios.delete(apiUrl + "dashboard/question/deleteMessage/"+action.ID, authHeader())
    if (response.data) {
      console.log("yorum delete başarılı",response.data)
      yield put({
        type: $.DELETE_QUESTION_ANSWER_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_QUESTION_ANSWER_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_QUESTION_ANSWER_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_QUESTION_ANSWER_REQUEST_END,
    });
  }
}

function* FUNC_DELETE_QUESTION(action){
  try {
    yield put({
      type: $.DELETE_QUESTION_REQUEST,
    });
    const response = yield axios.delete(apiUrl + "dashboard/question/delete/"+action.ID, authHeader())
    if (response.data) {
      console.log("soru delete başarılı",response.data)
      yield put({
        type: $.DELETE_QUESTION_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_QUESTION_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_QUESTION_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_QUESTION_REQUEST_END,
    });
  }
}

function* FUNC_UPDATE_QUESTION(action){
  try {
    yield put({
      type: $.UPDATE_QUESTION_REQUEST,
    });
    console.log('soru güncelleme gelenler',action)
    const response = yield axios.post(apiUrl + "dashboard/question/update/"+action.ID, action.payload, authHeader())
    if (response.data) {
      console.log("soru update başarılı",response.data)
      if(action.EK_DURUM>0){
        action.EKLER.map(item=>
          {
            store.dispatch({
              type: $.ADD_QUESTION_ATTACHMENT,
              ID:action.ID,
              payload: item,
            });
            
          }
        )
      }
      yield sleep(1)
      yield put({
        type: $.UPDATE_QUESTION_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.UPDATE_QUESTION_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.UPDATE_QUESTION_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.UPDATE_QUESTION_REQUEST_END,
    });
  }
}

function* FUNC_ADD_ARCHIVE(action){
  try {
    yield put({
      type: $.ADD_ARCHIVE_REQUEST,
    });
    console.log('gelen actionlar',action)
    const response = yield axios.post(apiUrl + "dashboard/archive/insert", action.payload, authHeader())
    if (response.data) {
      console.log("soru update başarılı",response.data)
      if(action.EK_DURUM>0){
        action.EKLER.map(item=>
          {
            store.dispatch({
              type: $.POST_UPLOAD_IMAGE_OR_PDF,
              ID:response.data.insertId,
              URI_UPLOAD:"userpost/uploadMevzuat",
              URI_UPDATE:"dashboard/archive/insertAttachment",
              payload: item,
            });
          }
        )
      }
      yield put({
        type: $.ADD_ARCHIVE_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.ADD_ARCHIVE_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.ADD_ARCHIVE_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.ADD_ARCHIVE_REQUEST_END,
    });
  }
}


function* FUNC_DELETE_ARCHIVE(action){
  try {
    yield put({
      type: $.DELETE_ARCHIVE_REQUEST,
    });
    const response = yield axios.delete(apiUrl + "dashboard/archive/delete/"+action.ID, authHeader())
    if (response.data) {
      console.log("archive delete başarılı",response.data)
      yield put({
        type: $.DELETE_ARCHIVE_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_ARCHIVE_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_ARCHIVE_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_ARCHIVE_REQUEST_END,
    });
  }
}

function* FUNC_GET_ARCHIVE_DETAIL(action){
  try {
    yield put({
      type: $.GET_ARCHIVE_DETAIL_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/archive/byid/"+action.ID, authHeader())
    if (response.data) {
      console.log("archive detail başarılı",response.data)
      yield put({
        type: $.GET_ARCHIVE_DETAIL_REQUEST_SUCCESS,
        PAYLOAD: response.data[0],
      });
    } else {
      yield put({
        type: $.GET_ARCHIVE_DETAIL_REQUEST_FAILURE,
        ERROR:response.data
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_ARCHIVE_DETAIL_REQUEST_FAILURE,
      ERROR:error
    });
  } finally {
    yield put({
      type: $.GET_ARCHIVE_DETAIL_REQUEST_END,
    });
  }
}

function* FUNC_UPDATE_ARCHIVE(action){
  try {
    yield put({
      type: $.UPDATE_ARCHIVE_REQUEST,
    });
    console.log('update için gelen veriler',action)
    const response = yield axios.post(apiUrl + "dashboard/archive/update/"+action.ID, action.payload ,authHeader())
    if (response.data) {
      console.log("archive delete başarılı",response.data)
      if(action.EK_DURUM>0){
        action.EKLER.map(item=>
          {
            store.dispatch({
              type: $.POST_UPLOAD_IMAGE_OR_PDF,
              URI_UPLOAD:"userpost/uploadMevzuat",
              URI_UPDATE:"dashboard/archive/insertAttachment",
              ID:action.ID,
              payload: item,
            });
          }
        )
        yield sleep(1); //wait 2 seconds
      }
      yield put({
        type: $.UPDATE_ARCHIVE_REQUEST_SUCCESS,
        PAYLOAD: response.data[0],
      });
    } else {
      yield put({
        type: $.UPDATE_ARCHIVE_REQUEST_FAILURE,
        ERROR:response.data
      });
    }
  } catch (error) {
    yield put({
      type: $.UPDATE_ARCHIVE_REQUEST_FAILURE,
      ERROR:error
    });
  } finally {
    yield put({
      type: $.UPDATE_ARCHIVE_REQUEST_END,
    });
  }
}


function* FUNC_ADD_ACTIVITIES(action){
  try {
    yield put({
      type: $.ADD_ACTIVITIES_REQUEST,
    });
    console.log("veri geldi",action.payload)
    if(action.IMAGE){
      console.log("image var işlem yapılacak")
      const formData = new FormData();
      formData.append("myImage", action.payload.ACTIVITY_IMAGE);
      const user = JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          'Accept': 'application/json',
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${user}`,
        },
      };
      const response = yield axios.post(apiUrl + "dashboard/category/upload", formData, {headers: {
        'Accept': 'application/json',
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      }});
      console.log("dönen response",response.data)
      action.payload.ACTIVITY_IMAGE=response.data.NAME
    }else {
      console.log("image yok koçum")
    }
    const response = yield axios.post(apiUrl + "dashboard/activities/insert",action.payload, authHeader())
    if (response.data) {
      console.log("activity insert başarılı",response.data)
      yield put({
        type: $.ADD_ACTIVITIES_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.ADD_ACTIVITIES_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.ADD_ACTIVITIES_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.ADD_ACTIVITIES_REQUEST_END,
    });
  }
}

function* FUNC_DELETE_ACTIVITIES(action){
  try {
    yield put({
      type: $.DELETE_ACTIVITIES_REQUEST,
    });
    const response = yield axios.delete(apiUrl + "dashboard/activities/delete/"+action.ID, authHeader())
    if (response.data) {
      console.log("activity delete başarılı",response.data)
      yield put({
        type: $.DELETE_ACTIVITIES_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_ACTIVITIES_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_ACTIVITIES_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_ACTIVITIES_REQUEST_END,
    });
  }
}

function* FUNC_UPDATE_ACTIVITIES(action){
  try {
    yield put({
      type: $.UPDATE_ACTIVITIES_REQUEST,
    });
    console.log("veri geldi",action.payload)
    if(action.IMAGE){
      console.log("image var işlem yapılacak")
      const formData = new FormData();
      formData.append("myImage", action.payload.ACTIVITY_IMAGE);
      const user = JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          'Accept': 'application/json',
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${user}`,
        },
      };
      const response = yield axios.post(apiUrl + "dashboard/category/upload", formData, {headers: {
        'Accept': 'application/json',
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      }});
      console.log("dönen response",response.data)
      action.payload.ACTIVITY_IMAGE=response.data.NAME
    }else {
      console.log("image yok koçum")
    }
    const response = yield axios.post(apiUrl + "dashboard/activities/update/"+action.ID,action.payload, authHeader())
    if (response.data) {
      console.log("activity update başarılı",response.data)
      yield put({
        type: $.UPDATE_ACTIVITIES_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.UPDATE_ACTIVITIES_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.UPDATE_ACTIVITIES_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.UPDATE_ACTIVITIES_REQUEST_END,
    });
  }
}

function* FUNC_SEND_NOTIFICATION_USER(action){
  try {
    yield put({
      type: $.SEND_NOTIFICATION_USER_REQUEST,
    });
    const response = yield axios.post(apiUrl + "dashboard/user/send_notification/"+action.ID, action.payload, authHeader())
    if (response.data) {
      console.log("notification send başarılı",response.data)
      yield put({
        type: $.SEND_NOTIFICATION_USER_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.SEND_NOTIFICATION_USER_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.SEND_NOTIFICATION_USER_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.SEND_NOTIFICATION_USER_REQUEST_END,
    });
  }
}

function* FUNC_GET_KURUMLAR_LIST(action){
  try {
    yield put({
      type: $.GET_KURUMLAR_LIST_REQUEST,
    });
    const response = yield axios.get(apiUrl + "dashboard/kurumlar/list", authHeader())
    if (response.data) {
      console.log("notification send başarılı",response.data)
      yield put({
        type: $.GET_KURUMLAR_LIST_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.GET_KURUMLAR_LIST_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.GET_KURUMLAR_LIST_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.GET_KURUMLAR_LIST_REQUEST_END,
    });
  }
}

function* FUNC_ADD_KURUM(action){
  try {
    yield put({
      type: $.ADD_KURUM_REQUEST,
    });
    console.log('gönderilecek kurumlar',action.PAYLOAD)
    const response = yield axios.post(apiUrl + "dashboard/kurumlar/insert", action.PAYLOAD, authHeader())
    if (response.data) {
      console.log("notification send başarılı",response.data)
      yield put({
        type: $.ADD_KURUM_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.ADD_KURUM_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.ADD_KURUM_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.ADD_KURUM_REQUEST_END,
    });
  }
}


function* FUNC_DELETE_KURUM(action){
  try {
    yield put({
      type: $.DELETE_KURUM_REQUEST,
    });
    console.log('gönderilecek kurumlar',action.PAYLOAD)
    const response = yield axios.delete(apiUrl + "dashboard/kurumlar/delete/"+action.ID, authHeader())
    if (response.data) {
      console.log("notification send başarılı",response.data)
      yield put({
        type: $.DELETE_KURUM_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_KURUM_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_KURUM_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_KURUM_REQUEST_END,
    });
  }
}

function* FUNC_UPDATE_KURUM(action){
  try {
    yield put({
      type: $.UPDATE_KURUM_REQUEST,
    });
    console.log('gönderilecek kurumlar',action.PAYLOAD)
    const response = yield axios.post(apiUrl + "dashboard/kurumlar/update/"+action.ID, action.PAYLOAD ,authHeader())
    if (response.data) {
      console.log("notification send başarılı",response.data)
      yield put({
        type: $.UPDATE_KURUM_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.UPDATE_KURUM_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.UPDATE_KURUM_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.UPDATE_KURUM_REQUEST_END,
    });
  }
}

function* FUNC_POST_UPLOAD_IMAGE_OR_PDF(action){
  try {
    yield put({
      type: $.POST_UPLOAD_IMAGE_OR_PDF_REQUEST,
    });
    yield sleep(1); //wait 2 seconds
    const formData = new FormData();
    formData.append("myImage", action.payload);
    const user = JSON.parse(localStorage.getItem("token"));
    const config = {
      headers: {
        'Accept': 'application/json',
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      },
    };
    console.log('resim yükleme gelen',action.payload)
    const response = yield axios.post(apiUrl + action.URI_UPLOAD, formData, {headers: {
      'Accept': 'application/json',
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user}`,
    }});
    console.log('response result',response.data)
    const responseInsert = yield axios.post(apiUrl + action.URI_UPDATE,{ARCHIVE_ID:action.ID,TYPE:action.payload.type=='application/pdf'?2:1,NAME:response.data.FILE_NAME}, {headers: {
      'Accept': 'application/json',
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user}`,
    }})
    console.log('pdf/img yükleme db sorug result',responseInsert)
    yield put({
      type: $.POST_UPLOAD_IMAGE_OR_PDF_REQUEST_SUCCESS,
    });
    return response.data

  } catch (error) {
    console.log('ek yüklenemedi haa oluştu',error)
    yield put({
      type: $.POST_UPLOAD_IMAGE_OR_PDF_REQUEST_FAILURE,
    });
  }
}

function sleep(sec) { 
  return new Promise(resolve => setTimeout(resolve, sec*1000)); 
}

function* FUNC_DELETE_ARCHIVE_ATTACHMENT(action){
  try {
    yield put({
      type: $.DELETE_ARCHIVE_ATTACHMENT_REQUEST,
    });
    console.log('gönderilecek kurumlar',action.PAYLOAD)
    const response = yield axios.delete(apiUrl + "dashboard/archive/deleteAttachment/"+action.ID, authHeader())
    if (response.data) {
      console.log("archive delete başarılı",response.data)
      yield put({
        type: $.DELETE_ARCHIVE_ATTACHMENT_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_ARCHIVE_ATTACHMENT_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_ARCHIVE_ATTACHMENT_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_ARCHIVE_ATTACHMENT_REQUEST_END,
    });
    yield FUNC_GET_ARCHIVE_DETAIL({ID:action.ARCHIVE_ID})
  }
}

function* FUNC_DELETE_QUESTION_ATTACHMENT(action){
  try {
    yield put({
      type: $.DELETE_QUESTION_ATTACHMENT_REQUEST,
    });
    console.log('silinecek ekler',action)
    const response = yield axios.delete(apiUrl + "dashboard/question/deleteAttachment/"+action.ATTACHMENT_ID, authHeader())
    if (response.data) {
      console.log("question ek silme",response.data)
      yield put({
        type: $.DELETE_QUESTION_ATTACHMENT_REQUEST_SUCCESS,
        PAYLOAD: response.data,
      });
    } else {
      yield put({
        type: $.DELETE_QUESTION_ATTACHMENT_REQUEST_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: $.DELETE_QUESTION_ATTACHMENT_REQUEST_FAILURE,
    });
  } finally {
    yield put({
      type: $.DELETE_QUESTION_ATTACHMENT_REQUEST_END,
    });
    yield FUNC_GET_QUESTION_LIST()
  }
}


function* FUNC_ADD_QUESTION_ATTACHMENT(action){
  try {
    yield put({
      type: $.ADD_QUESTION_ATTACHMENT_REQUEST,
    });
    console.log('soru ek ekleme gelenler',action)
    const formData = new FormData();
    formData.append('file', action.payload);
    const user = JSON.parse(localStorage.getItem("token"));
    const response = yield axios.post(apiUrl + 'userpost/fileupload', formData, {headers: {
      'Accept': 'application/json',
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user}`,
    }})
    .then(response => {
      const result = axios.post(apiUrl + 'userpost/additional', {QUESTION_ID:action.ID,FILE_NAME:response.data.FILE_NAME}, authHeader())
      return response;
    });

    yield put({
      type: $.ADD_QUESTION_ATTACHMENT_REQUEST_SUCCESS,
    });
  } catch (error) {
    console.log('Ek soru güncellenmedi',error)
    yield put({
      type: $.ADD_QUESTION_ATTACHMENT_REQUEST_FAILURE,
      ERROR:error
    });
    
  }
}