import { useState, useEffect } from "react";
import React from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'
import Projects from "../dashboard/components/Projects"
import {useSearchParams} from 'react-router-dom';


// Data
import userlistData from "./data/data";
import cevapListTable from "./data/cevapList";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [LOADER, SET_LOADER] = useState(false);
  const [ANSWERS, SET_ANSWERS] = useState([]);
  
  
  useEffect(() => {
    if(props.QUESTION_LIST[0]?.ID){
      let data = props.QUESTION_LIST.filter(item=>item.ID==searchParams.get('id'))[0];
      if(data?.ID>0){
        SET_ANSWERS(data.ANSWERS) 
      }else {
        alert('Böyle bir Kategori bulunmamaktadır.')
      }
    }else {
      props.getQuestion();
    }
  }, [props.QUESTION_LIST]);

  if(searchParams.has('delete_comment_id')){
    if(location.search.split("=")[2]>0){
      if (confirm('Bu yorumu silmek istiyor musunuz?') == true) {
        props.deleteAnswer(searchParams.get('delete_comment_id'))
        history("/soru/cevapList/?id=" +searchParams.get('id'))
      } else {
        history("/soru/cevapList/?id=" +searchParams.get('id'))
      }
    }
  }

    
  useEffect(() => {
    if (props.DELETE_QUESTION_ANSWER_STATUS==1) {
      SET_LOADER(true)
    }else if(props.DELETE_QUESTION_ANSWER_STATUS==2){
      props.getQuestion();
      SET_LOADER(false)
      alert('Yorum başarıyla silindi')
    }else if(props.DELETE_QUESTION_ANSWER_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.DELETE_QUESTION_ANSWER_ERROR))
    }
  }, [props.DELETE_QUESTION_ANSWER_STATUS])

  let comment = cevapListTable(ANSWERS);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {ANSWERS[0]?.ID>0?
      <SuiBox p={9}>
        <Grid item xs={12} md={12} lg={12}>
          {ANSWERS.length>0?
            <Projects headerName={'Haber Yorumları'} deleteAction={()=>alert('silinecek')} columns1={comment.columns1} rows2={comment.rows2} />
            :
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Yorum yok
            </SuiTypography>
          }
        </Grid>
      </SuiBox>
      :
      <Oval height = "15" width = "15"/>
      }
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { QUESTION_LIST, DELETE_QUESTION_ANSWER_LOADER, DELETE_QUESTION_ANSWER_STATUS } = state.app;
  return { QUESTION_LIST, DELETE_QUESTION_ANSWER_LOADER, DELETE_QUESTION_ANSWER_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  getQuestion: () => {
    dispatch({
      type: $.GET_QUESTION_LIST
    });
  }, 
  deleteAnswer: (ID) => {
    dispatch({
      type: $.DELETE_QUESTION_ANSWER,
      ID
    });
  },  
  
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
