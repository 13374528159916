import { useState, useEffect } from "react";
import React from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'
import Projects from "../dashboard/components/Projects"
import {useSearchParams} from 'react-router-dom';


// Data
import userlistData from "./data/data";
import YorumTable from "./data/yorumlar";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ISSUE, SET_ISSUE] = useState([]);
  const [LOADER, SET_LOADER] = useState(false);
  const [ISSUE_HEADER, SET_ISSUE_HEADER] = useState('');
  const [ISSUE_CONTENT, SET_ISSUE_CONTENT] = useState('');
  const [IS_COMMENT, SET_IS_COMMENT] = useState('');
  const [VIEW_COUNT, SET_VIEW_COUNT] = useState(0);
  const [NEWSPAPER_PUBLISH_DATE, SET_NEWSPAPER_PUBLISH_DATE] = useState(new Date());
  const [IMAGE_SHOW, SET_IMAGE_SHOW] = useState(false);
  const [IMAGE_UPLOAD, SET_IMAGE_UPLOAD] = useState('');
  const [IMAGE_SHOW_URI, SET_IMAGE_SHOW_URI] = useState('');
  const [COMMENTS_HAVE, SET_COMMENTS_HAVE] = useState(false);
  const [COMMENTS, SET_COMMENTS] = useState([]);
  const [DELETE_ISSUE_CONFIRM, SET_DELETE_ISSUE_CONFIRM] = useState(false);
  const [DELETE_ISSUE_COMMENT_CONFIRM, SET_DELETE_ISSUE_COMMENT_CONFIRM] = useState(false);
  const [DELETE_ISSUE_COMMENT_CONFIRM_ID, SET_DELETE_ISSUE_COMMENT_CONFIRM_ID] = useState(0);

  if(searchParams.has('delete_comment_id')){
    if(location.search.split("=")[2]>0){
      if (confirm('Bu yorumu silmek istiyor musunuz?') == true) {
        props.deleteIssueComment(searchParams.get('delete_comment_id'))
        history("/haber/detay/?id=" +searchParams.get('id'))
      } else {
        history("/haber/detay/?id=" +searchParams.get('id'))
      }
    }
  }
  
  
  useEffect(() => {
    if (props.DELETE_ISSUE_COMMENT_STATUS==1) {
      SET_LOADER(true)
    }else if(props.DELETE_ISSUE_COMMENT_STATUS==2){
      props.getIssueList();
      SET_LOADER(false)
      alert('Yorum başarıyla silindi')
    }else if(props.DELETE_ISSUE_COMMENT_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.DELETE_ISSUE_COMMENT_ERROR))
    }
  }, [props.DELETE_ISSUE_COMMENT_STATUS])


  useEffect(() => {
    if(props.ISSUE_LIST[0]?.ID){
      console.log("redirect",redirect)
      let data = props.ISSUE_LIST.filter(item=>item.ID==searchParams.get('id'))[0];
      if(data?.ID>0){
        console.log("seçilen haber",data)
        SET_ISSUE(data)
        SET_ISSUE_HEADER(data.ISSUE_HEADER);
        SET_ISSUE_CONTENT(data.ISSUE_CONTENT);
        SET_IS_COMMENT(data.IS_COMMENT);
        SET_VIEW_COUNT(data.VIEW_COUNT);
        SET_NEWSPAPER_PUBLISH_DATE(data.NEWSPAPER_PUBLISH_DATE)
        if(data.COMMENTS){
          console.log("comment var")
          let comment = YorumTable({data:data.COMMENTS,parentMethod:()=>console.log("ok")});
          SET_COMMENTS(comment)
          SET_COMMENTS_HAVE(true)
        }
         
      }else {
        alert('Böyle bir haber bulunmamaktadır.')
      }
    }else {
      props.getIssueList();
    }
  }, [props.ISSUE_LIST]);

  
 
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      SET_IMAGE_SHOW(true)
      SET_IMAGE_SHOW_URI(URL.createObjectURL(img))
      SET_IMAGE_UPLOAD(img)
      console.log("gelen resim",img)
      // props.uploadImage(ISSUE.ID,img);
    }
  };

  useEffect(() => {
    if (DELETE_ISSUE_CONFIRM) {
      let text = "Gerçekten silmek istiyor musunuz ?";
      if (confirm(text) == true) {
        props.deleteIssue(ISSUE.ID)
      } else {
        SET_DELETE_ISSUE_CONFIRM(false)
      }
    }
  }, [DELETE_ISSUE_CONFIRM])

  useEffect(() => {
    if (props.DELETE_ISSUE_STATUS==1) {
      SET_LOADER(true)
    }else if(props.DELETE_ISSUE_STATUS==2){
      props.getIssueList();
      SET_LOADER(false)
      alert('Haber başarıyla silindi')
      history("/haberler")
    }else if(props.DELETE_ISSUE_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.DELETE_ISSUE_ERROR))
    }
  }, [props.DELETE_ISSUE_STATUS])
  
  useEffect(() => {
    if (props.UPDATE_ISSUE_STATUS==1) {
      SET_LOADER(true)
    }else if(props.UPDATE_ISSUE_STATUS==2){
      alert('Haber güncellendi')
      SET_LOADER(false)
      props.getIssueList();
    }else if(props.UPDATE_ISSUE_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.UPDATE_ISSUE_ERROR))
    }
  }, [props.UPDATE_ISSUE_STATUS])
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {ISSUE?.ID>0?
      <SuiBox p={9}>
        <SuiBox component="form" role="form">
          <SuiBox mb={9} ml={0.5}>
            <Grid container spacing={1}>
              <Grid md={12} xl={12}>
                
              </Grid>
              <Grid md={9} xl={9}>
                  <SuiBox
                    component="img"
                    src={IMAGE_SHOW?URL.createObjectURL(IMAGE_UPLOAD):apiUrl + "image/show/" + ISSUE.ISSUE_IMAGE}
                    height="200px"
                  />
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Haber Fotoğrafı
                    </SuiTypography>
                    <input type="file" disabled={LOADER} className="mb-2" name="myImage"  onChange={(event) => onImageChange(event)}/>
                  </SuiBox>
                </Grid>
                <Grid md={3} xl={2} >
                  <SuiButton color="info" disabled={LOADER} fullWidth onClick={()=>SET_DELETE_ISSUE_CONFIRM(true)} >
                    {LOADER?
                    <Oval height = "15" width = "15"/>
                    :
                    'Haberi Sil'
                    }
                  </SuiButton>
                </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Haber Başlığı
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Haber Başlığı"
                    value={ISSUE_HEADER}
                    onChange={(e) => SET_ISSUE_HEADER(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item md={12} xl={6}>
              </Grid>
              <Grid item xs={12} sm={12} xl={12}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Haber İçeriği
                    </SuiTypography>
                  </SuiBox>
                   <CKEditor
                    editor={ ClassicEditor }
                    data={ISSUE_CONTENT}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                        SET_ISSUE_CONTENT(data)
                    } }
                />
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid md={12} xl={6}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Görüntülenme
                </SuiTypography>
                <SuiInput
                  type="text"
                  disabled
                  placeholder="Görüntülenme sayısı"
                  value={VIEW_COUNT}
                />
              </Grid>
              <Grid md={12} xl={6}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Yayınlanma Tarihi
                </SuiTypography>
                <SuiInput
                  type="text"   
                  disabled
                  placeholder="Yayınlanma"
                  value={NEWSPAPER_PUBLISH_DATE}
                />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={7}>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCardNew
              title={{ text: "Haber Yorum Durumu" }}
              count={IS_COMMENT==1?'Yorum Aktif':'Yorum Kapalı'}
              percentage={{ color: "success"}}
              action={()=> SET_IS_COMMENT(prompt(IS_COMMENT==1?'Kapatmak için 0 yazınız':'Açmak için 1 yazınız'))}
              icon={{
                color: "info",
                component: "edit",
              }}
              />
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
              {COMMENTS_HAVE?
                <Projects headerName={'Haber Yorumları'} deleteAction={()=>alert('silinecek')} columns1={COMMENTS.columns1} rows2={COMMENTS.rows2} />
                :
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Yorum yok
                </SuiTypography>
               }
            </Grid>
          </Grid>

          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={LOADER}
              onClick={()=>props.updateIssue(ISSUE.ID,IMAGE_SHOW?IMAGE_UPLOAD:false,{ISSUE_HEADER,ISSUE_CONTENT,IS_COMMENT})}
            >
              {LOADER?
              <Oval height = "15" width = "15"/>
              :
              'Güncelle'
              }
            </SuiButton>
          </SuiBox>

        </SuiBox>

      </SuiBox>
      :
      <Oval height = "15" width = "15"/>
      }
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { ISSUE_LIST, ISSUE_LIST_LOADER, ISSUE_LIST_STATUS, DELETE_ISSUE_LOADER, DELETE_ISSUE_STATUS, UPDATE_ISSUE_LOADER, UPDATE_ISSUE_STATUS, DELETE_ISSUE_COMMENT_LOADER, DELETE_ISSUE_COMMENT_STATUS } = state.app;
  return { ISSUE_LIST, ISSUE_LIST_LOADER, ISSUE_LIST_STATUS, DELETE_ISSUE_LOADER, DELETE_ISSUE_STATUS, UPDATE_ISSUE_LOADER, UPDATE_ISSUE_STATUS, DELETE_ISSUE_COMMENT_LOADER, DELETE_ISSUE_COMMENT_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  getIssueList: () => {
    dispatch({
      type: $.GET_ISSUE_LIST
    });
  },
  deleteIssue: (ID) => {
    dispatch({
      type: $.DELETE_ISSUE,
      ID
    });
  },
  updateIssue: (ID,IMAGE,PAYLOAD) => {
    dispatch({
      type: $.UPDATE_ISSUE,
      ID,
      IMAGE,
      PAYLOAD,
    });
  },
  deleteIssueComment: (ID) => {
    dispatch({
      type: $.DELETE_ISSUE_COMMENT,
      ID
    });
  },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
