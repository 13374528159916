import { useState, useEffect } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiButton from "components/SuiButton";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";


// Data
import userlistData from "./data/userListData";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, NavLink } from "react-router-dom";
import {useSearchParams} from 'react-router-dom';

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  let history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [LOADER, SET_LOADER] = useState(false);

  useEffect(() => {
    if(props.ACTIVITIES_LIST&&props.ACTIVITIES_LIST.length>0){
    }else {
      props.getData();
    }
  }, [props.ACTIVITIES_LIST]);

  if(searchParams.has('delete_comment_id')){
    if(location.search.split("=")[1]>0){
      if (confirm('Bu etkinliği silmek istiyor musunuz?') == true) {
        props.deleteDate(searchParams.get('delete_comment_id'))
        history("/etkinlikler")
      } else {
        history("/etkinlikler")
      }
    }
  }
  useEffect(() => {
    if (props.DELETE_ACTIVITIES_STATUS==1) {
      SET_LOADER(true)
    }else if(props.DELETE_ACTIVITIES_STATUS==2){
      props.getData();
      SET_LOADER(false)
      alert('Etkinlik başarıyla silindi')
      history("/etkinlikler")

    }else if(props.DELETE_ACTIVITIES_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.DELETE_ACTIVITIES_ERROR))
    }
  }, [props.DELETE_ACTIVITIES_STATUS])

  const { columns1, rows2 } = userlistData(props.ACTIVITIES_LIST);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
      <Grid container mb={6} spacing={1}>
        <Grid md={3} xl={10} >
        </Grid>
        <Grid md={3} xl={2} >
          <NavLink
          to={{ pathname: "/etkinlik/ekle"}} className="nav-link" rel="noopener noreferrer" >
            <SuiButton variant="gradient" color="info" fullWidth >
              Etkinlik Ekle
            </SuiButton>
          </NavLink>
        </Grid>
      </Grid>
      <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns1} rows={rows2} />
            </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { ACTIVITIES_LIST, ACTIVITIES_LIST_LOADER, ACTIVITIES_LIST_STATUS, DELETE_ACTIVITIES_STATUS, DELETE_ACTIVITIES_LOADER} = state.app;
  return { ACTIVITIES_LIST, ACTIVITIES_LIST_LOADER, ACTIVITIES_LIST_STATUS, DELETE_ACTIVITIES_STATUS, DELETE_ACTIVITIES_LOADER};
};

const mapDispatchToProps = (dispatch, props) => ({
  getData: () => {
    dispatch({
      type: $.GET_ACTIVITIES_LIST
    });
  },
  deleteDate: (ID) => {
    dispatch({
      type: $.DELETE_ACTIVITIES,
      ID
    });
  },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
