import { useState, useEffect } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";


// Data
import userlistData from "./data/userListData";

import { connect } from "react-redux";
import * as $ from "../../redux/action";

function Dashboard(props) {
  useEffect(() => {
    if(props.USER_LIST&&props.USER_LIST.length>0){
    }else {
      props.getUserList();
    }
  }, [props.USER_LIST]);

  const { columns1, rows2 } = userlistData(props.USER_LIST);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
      <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns1} rows={rows2} />
            </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { USER_LIST, USER_LIST_LOADER, USER_LIST_STATUS } = state.app;
  return { USER_LIST, USER_LIST_LOADER, USER_LIST_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  getUserList: () => {
    dispatch({
      type: $.GET_USER_LIST
    });
  },  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
