import { useState, useEffect } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Oval } from 'react-loader-spinner'


// Data
import userlistData from "./data/userListData";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {apiUrl} from "../../config/index";

function Dashboard(props) {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  const [USER, SET_USER] = useState(props.USER_LIST.filter(item=>item.ID==redirect)[0]);
  const [NAME_SURNAME, SET_NAME_SURNAME] = useState('');
  const [EMAIL, SET_EMAIL] = useState('');
  const [DEVICE_INFO, SET_DEVICE_INFO] = useState('');
  const [DEVICE_ID, SET_DEVICE_ID] = useState('');
  const [PHONE_NOTIFICATION, SET_PHONE_NOTIFICATION] = useState('');
  const [EMAIL_NOTIFICATION, SET_EMAIL_NOTIFICATION] = useState('');
  const [ADMIN, SET_ADMIN] = useState('');
  const [STATUS, SET_STATUS] = useState('');
  const [DELETE_USER_CONFIRM, SET_DELETE_USER_CONFIRM] = useState(false);
  const [LOADER, SET_LOADER] = useState(false);
  let history = useNavigate();

  useEffect(() => {
    if(props.USER_LIST[0]?.ID){
      let data = props.USER_LIST.filter(item=>item.ID==redirect)[0];
      if(data?.ID>0){
        SET_USER(data)
        SET_NAME_SURNAME(data.NAME_SURNAME);
        SET_EMAIL(data.EMAIL);
        SET_DEVICE_INFO(data.DEVICE_INFO);
        SET_DEVICE_ID(data.DEVICE_ID);
        SET_PHONE_NOTIFICATION(data.PHONE_NOTIFICATION);
        SET_EMAIL_NOTIFICATION(data.EMAIL_NOTIFICATION);
        SET_ADMIN(data.ADMIN);
        SET_STATUS(data.STATUS);
      }else {
        alert('Böyle bir kullanıcı bulunmamaktadır.')
      }
    }else {
      props.getUserList();
    }
  }, [props.USER_LIST]);

  useEffect(() => {
    if (DELETE_USER_CONFIRM) {
      let text = "Gerçekten silmek istiyor musunuz ?";
      if (confirm(text) == true) {
        props.deleteUser(USER.ID)
      } else {
        SET_DELETE_USER_CONFIRM(false)
      }
    }
  }, [DELETE_USER_CONFIRM])

  useEffect(() => {
    if (props.DELETE_USER_STATUS==1) {
      SET_LOADER(true)
    }else if(props.DELETE_USER_STATUS==2){
      props.getUserList();
      SET_LOADER(false)
      alert('Kullanıcı başarıyla silindi')
      history("/kullanici")
    }else if(props.DELETE_USER_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.DELETE_USER_ERROR))
    }
  }, [props.DELETE_USER_STATUS])
  
  useEffect(() => {
    if (props.RESET_PASSWORD_USER_STATUS==1) {
      SET_LOADER(true)
    }else if(props.RESET_PASSWORD_USER_STATUS==2){
      SET_LOADER(false)
      alert('Kullanıcı şifresi sıfırlandı')
    }else if(props.RESET_PASSWORD_USER_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.RESET_PASSWORD_USER_ERROR))
    }
  }, [props.RESET_PASSWORD_USER_STATUS])
  
  useEffect(() => {
    if (props.UPDATE_USER_STATUS==1) {
      SET_LOADER(true)
    }else if(props.UPDATE_USER_STATUS==2){
      alert('Kullanıcı güncellendi')
      SET_LOADER(false)
      props.getUserList();
    }else if(props.UPDATE_USER_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.UPDATE_USER_ERROR))
    }
  }, [props.UPDATE_USER_STATUS])
  
  useEffect(() => {
    if (props.SEND_NOTIFICATION_USER_STATUS==1) {
      SET_LOADER(true)
    }else if(props.SEND_NOTIFICATION_USER_STATUS==2){
      alert('Notification gönderildi')
      SET_LOADER(false)
    }else if(props.SEND_NOTIFICATION_USER_STATUS==3){
      SET_LOADER(false)
      alert('Hata oluştu:'+JSON.stringify(props.UPDATE_USER_ERROR))
    }
  }, [props.SEND_NOTIFICATION_USER_STATUS])
  
  
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      console.log("gelen resim",img)
      props.uploadImage(USER.ID,img);
    }
  };

  const sendNotification = () => {
    let HEADER = prompt('Bildirim başlığını giriniz')
    let CONTENT = prompt('Bildirim içeriğini giriniz')
    let text = HEADER+" Başlıklı "+CONTENT+" içerikli bildirim gönderilecektir, onaylıyor musunuz?";
    if (confirm(text) == true) {
      console.log("notification",HEADER,CONTENT)
      props.sendNotification(USER.ID,{HEADER,CONTENT})
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {USER?.ID>0?
      <SuiBox p={9}>
        <SuiBox component="form" role="form">
          <SuiBox mb={9} ml={0.5}>
            <Grid container spacing={1}>
              <Grid md={12} xl={12}>
                
              </Grid>
              <Grid md={3} xl={3}>
                  <SuiBox
                    component="img"
                    src={apiUrl + "image/showProfile/" + USER.PROFILE_IMAGE}
                    width="50%"
                  />
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Kullanıcı Profil Resmi
                    </SuiTypography>
                    <input type="file" disabled={LOADER} className="mb-2" name="myImage"  onChange={(event) => onImageChange(event)}/>
                  </SuiBox>
                </Grid>
                <Grid md={3} xl={2} >
                  <SuiButton color="info" disabled={LOADER} fullWidth  onClick={() => SET_DELETE_USER_CONFIRM(true)}>
                    {LOADER?
                    <Oval height = "15" width = "15"/>
                    :
                    'Kullanıcıyı Sil'
                    }
                  </SuiButton>
                </Grid>
                <Grid md={3} xl={2} mr={2} ml={2}>
                  <SuiButton color="secondary" disabled={LOADER} fullWidth onClick={()=>props.userResetPassword(USER.ID,prompt('Yeni şifreyi giriniz'))}>
                    {LOADER?
                    <Oval height = "15" width = "15"/>
                    :
                    'Şifre Sıfırla'
                    }
                  </SuiButton>
                </Grid>
                <Grid md={3} xl={2} mr={2} ml={2}>
                  <SuiButton color="primary" disabled={LOADER} fullWidth onClick={()=>sendNotification()}>
                    {LOADER?
                    <Oval height = "15" width = "15"/>
                    :
                    'Notification Gönder'
                    }
                  </SuiButton>
                </Grid>
                <Grid md={3} xl={2} >
                  <SuiButton disabled={LOADER} variant={USER.ID,USER.STATUS==1?"contained":"gradient"} fullWidth color={USER.ID,USER.STATUS==1?"info":"success"} onClick={()=>props.userMakePassive(USER.ID,USER.STATUS==1?0:1)}  >
                    {LOADER?
                    <Oval height = "15" width = "15"/>
                    :
                    USER.STATUS==1?'Pasife Çek':'Aktif Et'
                    }
                  </SuiButton>
                </Grid>
              <Grid item md={12} xl={6}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      İsim Soyisim
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="İsim Soyisim"
                    value={NAME_SURNAME}
                    onChange={(e) => SET_NAME_SURNAME(e.target.value)}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={6} xl={6}>
                <SuiBox mb={2}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Email
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="text"
                    placeholder="Email"
                    value={EMAIL}
                    onChange={(e) => SET_EMAIL(e.target.value)}
                  />
                </SuiBox>
              </Grid>
            </Grid>
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Cihaz Bilgisi
                </SuiTypography>
              </SuiBox>
              <SuiInput
                type="text"
                disabled
                placeholder="Cihaz Bilgisi"
                value={DEVICE_INFO}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Cihaz ID
                </SuiTypography>
              </SuiBox>
              <SuiInput
                size="large"
                type="text"   
                disabled
                placeholder="Cihaz ID"
                value={DEVICE_ID}
              />
            </SuiBox>
          </SuiBox>

          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={LOADER}
              onClick={()=>props.userEdit(USER.ID,EMAIL,NAME_SURNAME)}
            >
              {LOADER?
              <Oval height = "15" width = "15"/>
              :
              'Güncelle'
              }
            </SuiButton>
          </SuiBox>

        </SuiBox>

      </SuiBox>
      :
      <Oval height = "15" width = "15"/>
      }
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { USER_LIST, USER_LIST_LOADER, USER_LIST_STATUS, DELETE_USER_STATUS, DELETE_USER_ERROR, RESET_PASSWORD_USER_LOADER, RESET_PASSWORD_USER_STATUS, UPDATE_USER_STATUS, UPDATE_USER_ERROR, SEND_NOTIFICATION_USER_LOADER, SEND_NOTIFICATION_USER_STATUS} = state.app;
  return { USER_LIST, USER_LIST_LOADER, USER_LIST_STATUS, DELETE_USER_STATUS, DELETE_USER_ERROR, RESET_PASSWORD_USER_LOADER, RESET_PASSWORD_USER_STATUS, UPDATE_USER_STATUS, UPDATE_USER_ERROR, SEND_NOTIFICATION_USER_LOADER, SEND_NOTIFICATION_USER_STATUS};
};

const mapDispatchToProps = (dispatch, props) => ({
  getUserList: () => {
    dispatch({
      type: $.GET_USER_LIST
    });
  },  
  getUserList: () => {
    dispatch({
      type: $.GET_USER_LIST
    });
  },
  deleteUser: (ID) => {
    dispatch({
      type: $.DELETE_USER,
      ID
    });
  },
  userResetPassword: (ID,PASSWORD) => {
    dispatch({
      type: $.RESET_PASSWORD_USER,
      ID,
      payload: {PASSWORD}
    });
  },
  userMakePassive: (ID,STATUS) => {
    dispatch({
      type: $.UPDATE_USER,
      ID,
      payload: {STATUS:STATUS}
    });
  },
  userEdit: (ID,EMAIL,NAME_SURNAME) => {
    dispatch({
      type: $.UPDATE_USER,
      ID,
      payload: {EMAIL,NAME_SURNAME}
    });
  },
  uploadImage: (ID,PROFILE_IMAGE) => {
    dispatch({
      type: $.CHANGE_PROFILE_IMAGE_USER,
      ID,
      PROFILE_IMAGE
    });
  },
  sendNotification: (ID,payload) => {
    dispatch({
      type: $.SEND_NOTIFICATION_USER,
      ID,
      payload
    });
  },
  
  
  
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
