import { useState, useEffect } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import Table from "examples/Tables/Table";


// Data
import data from "./data/data";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate, NavLink } from "react-router-dom";

function Dashboard(props) {
  useEffect(() => {
    if(props.CATEGORY_LIST&&props.CATEGORY_LIST.length>0){
    }else {
      props.getCategory();
    }
  }, [props.CATEGORY_LIST]);

  const { columns1, rows2 } = data(props.CATEGORY_LIST);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
      <Grid container mb={6} spacing={1}>
        <Grid md={3} xl={10} >
        </Grid>
        <Grid md={3} xl={2} >
          <NavLink
          to={{ pathname: "/kategori/ekle"}} className="nav-link" rel="noopener noreferrer" >
            <SuiButton variant="gradient" color="info" fullWidth >
              Kategori Ekle
            </SuiButton>
          </NavLink>
        </Grid>
      </Grid>
      <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns1} rows={rows2} />
            </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { CATEGORY_LIST, CATEGORY_LIST_LOADER, CATEGORY_LIST_STATUS } = state.app;
  return { CATEGORY_LIST, CATEGORY_LIST_LOADER, CATEGORY_LIST_STATUS };
};

const mapDispatchToProps = (dispatch, props) => ({
  getCategory: () => {
    dispatch({
      type: $.GET_CATEGORY_LIST
    });
  },  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
