import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Projects from "./components/Projects"

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import MiniStatisticsCardNew from "examples/Cards/StatisticsCards/MiniStatisticsCard/indexNew";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";


// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";

import { connect } from "react-redux";
import * as $ from "../../redux/action";
import { useNavigate } from "react-router-dom";
import { PortableWifiOffSharp } from "@mui/icons-material";
import userlistData from "../user/data/userListData";
import HaberTable from "../haberler/data/data";

function Dashboard(props) {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const [TOTAL_USER, SET_TOTAL_USER] = useState(0);
  const [TODAY_ACTIVE_USER, SET_TODAY_ACTIVE_USER] = useState(0);
  const [TOTAL_ISSUE, SET_TOTAL_ISSUE] = useState(0);
  const [TOTAL_ARCHIVE, SET_TOTAL_ARCHIVE] = useState(0);
  const [CONFIG, SET_CONFIG] = useState([]);

  useEffect(() => {
    if(props.SUMMARY&&props.SUMMARY?.TOTAL_USER){
      SET_TOTAL_USER(props.SUMMARY.TOTAL_USER[0].DATA)
      SET_TODAY_ACTIVE_USER(props.SUMMARY.TODAY_ACTIVE_USER[0].DATA)
      SET_TOTAL_ISSUE(props.SUMMARY.TOTAL_ISSUE[0].DATA)
      SET_TOTAL_ARCHIVE(props.SUMMARY.TOTAL_ARCHIVE[0].DATA)
      SET_CONFIG(props.CONFIG[0])
    }else {
      props.getSummary();
      props.getUserList();
      props.getQuestionList();
      props.getIssueList();
      props.getArchiveList();
      props.getActivitiesList();
      props.getCategoryList();
      props.getConfig()
    }
  }, [props.SUMMARY]);

  useEffect(() => {
    if(props.CONFIG_EDIT_STATUS==2){
      props.getConfig()
      alert('Config başarılı bir şekilde güncellendi')
    }
  }, [props.CONFIG_EDIT_STATUS]);

  useEffect(() => {
    SET_CONFIG(props.CONFIG[0])
  }, [props.CONFIG]);

  const { columns1, rows2 } = userlistData(props.USER_LIST.slice(0, 5));
  const issue = HaberTable(props.ISSUE_LIST.slice(0, 5));
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Toplam kullanıcı" }}
                count={TOTAL_USER}
                percentage={{ color: "success" }}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Bugün aktif kullanıcı" }}
                count={TODAY_ACTIVE_USER}
                percentage={{ color: "success" }}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Haber sayısı" }}
                count={TOTAL_ISSUE}
                percentage={{ color: "success" }}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Veri sayısı" }}
                count={TOTAL_ARCHIVE}
                percentage={{ color: "success"}}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <SuiTypography variant="h5" fontWeight="medium">
        Ayarlar
      </SuiTypography>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={4}>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCardNew
              title={{ text: "Reklam Gösterim İşlem Sayısı" }}
              count={CONFIG?.ADS_COUNT?CONFIG?.ADS_COUNT:0}
              percentage={{ color: "success"}}
              action={()=> props.editConfig({ADS_COUNT:prompt("Reklam gösterim işlem sayısını giriniz:")})}
              icon={{
                color: "info",
                component: "edit",
              }}
              />
            </Grid>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCardNew
              title={{ text: "Android Version Kontrol" }}
              count={CONFIG?.ANDROID_STATUS==0?'PASİF':CONFIG?.ANDROID_VERSION}
              percentage={{ color: "success"}}
              action={()=> CONFIG?.ANDROID_STATUS==0?props.editConfig({ANDROID_STATUS:prompt("Aktif etmek için 1 yazınız.")}):props.editConfig({ANDROID_VERSION:prompt("Güncellenecek version numarasını giriniz:")})}
              icon={{
                color: "info",
                component: "edit",
              }}
              />
            </Grid>
            <Grid item xs={4} sm={4} xl={3}>
              <MiniStatisticsCardNew
              title={{ text: "iOS Version Kontrol" }}
              count={CONFIG?.IOS_STATUS==0?'PASİF':CONFIG?.IOS_VERSION}
              percentage={{ color: "success"}}
              action={()=> CONFIG?.IOS_STATUS==0?props.editConfig({IOS_STATUS:prompt("Aktif etmek için 1 yazınız.")}):props.editConfig({IOS_VERSION:prompt("Güncellenecek version numarasını giriniz:")})}
              icon={{
                color: "info",
                component: "edit",
              }}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <SuiTypography variant="h5" fontWeight="medium">
        Güncel
      </SuiTypography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Projects headerName={'Kayıt olan son kullanıcılar'} columns1={columns1} rows2={rows2} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Projects headerName={'Eklenen habereler'} columns1={issue.columns1} rows2={issue.rows2} />
        </Grid>
      </Grid>
    
    </DashboardLayout>
  );
}

const mapStateToProps = (state, props) => {
  const { SUMMARY, SUMMARY_LOADER, SUMMARY_STATUS , CONFIG, CONFIG_EDIT_STATUS, ISSUE_LIST, USER_LIST, USER_LIST_STATUS} = state.app;
  return { SUMMARY, SUMMARY_LOADER, SUMMARY_STATUS , CONFIG, CONFIG_EDIT_STATUS, ISSUE_LIST, USER_LIST, USER_LIST_STATUS};
};

const mapDispatchToProps = (dispatch, props) => ({
  getSummary: () => {
    dispatch({
      type: $.GET_SUMMARY
    });
  },
  getUserList: () => {
    dispatch({
      type: $.GET_USER_LIST
    });
  },
  getQuestionList: () => {
    dispatch({
      type: $.GET_QUESTION_LIST
    });
  },
  getIssueList: () => {
    dispatch({
      type: $.GET_ISSUE_LIST
    });
  },
  getArchiveList: () => {
    dispatch({
      type: $.GET_ARCHIVE_LIST
    });
  },
  getActivitiesList: () => {
    dispatch({
      type: $.GET_ACTIVITIES_LIST
    });
  },
  getCategoryList: () => {
    dispatch({
      type: $.GET_CATEGORY_LIST
    });
  },
  getConfig: () => {
    dispatch({
      type: $.GET_CONFIG
    });
  },
  editConfig: (payload) => {
    dispatch({
      type: $.EDIT_CONFIG,
      payload
    });
  },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
